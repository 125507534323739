import _ from 'lodash'
import moment from 'moment'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import clsx from 'clsx'
import {KTSVG} from '../../../_metronic/helpers'
import {AdminPanelCompanyDTO} from '../../api/data-contracts'
import AdminCompanyDetailPopup from '../../pages/admin/Companies/AdminCompanyDetailPopup'
import CompanyStateProgressBar from '../../pages/admin/Components/CompanyStateProgressBar'
import ExportCSV from '../../pages/admin/Components/ExportCSV'
import {getUserState} from '../../redux/userSlice'
import {useSelector} from 'react-redux'
import {CompanySuspensionStatusEnum, UserTypeEnum} from '../../apiClient/enums'
import AssignedCompanyPopup from '../../pages/assignedCompanies/components/AssignedCompanyPopup'

function CompaniesTable(props: {companies: AdminPanelCompanyDTO[]}) {
  const role = useSelector(getUserState).data?.roleId as UserTypeEnum

  const columns: ColumnDescription[] = [
    {
      dataField: 'shortName',
      text: 'Adı',
      sort: true,
      headerStyle: {width: '20%'},
      formatter: (cell: any, row: AdminPanelCompanyDTO, rowIndex: number, colIndex: number) => (
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <div className='text-dark fw-bold fs-6'>{row.shortName}</div>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              {(row.referenceId ?? 0) > 0 && row.referenceName + ' Referansı'}
            </span>
            {role == UserTypeEnum.Admin && (
              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                {(row.assignedFinancialAdvisorId ?? 0) > 0 && 'MM: ' + row.assignedFinancialAdvisor}
              </span>
            )}
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              {row.isNewCompany ? 'Yeni Şirket' : 'Kurulu Şirket'}
            </span>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              {row.suspensionStatus}
            </span>
          </div>
        </div>
      ),
    },
    {
      dataField: 'firstName',
      text: 'Kullanıcı',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '15%'},
      formatter: (cell: any, m: AdminPanelCompanyDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>
            {m.firstName} {m.lastName}{' '}
            {m.isUserActive !== true && (
              <KTSVG
                path='/media/icons/duotune/general/gen042.svg'
                className='svg-icon svg-icon-danger'
              />
            )}
          </div>
          <span className='text-muted fw-semibold text-muted d-block fs-7'>
            {m.mailAddress}
            <br />
            {m.phoneNumber}
          </span>
        </>
      ),
    },
    {
      dataField: 'createTime',
      text: 'Kayıt Zamanı',
      sort: true,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: AdminPanelCompanyDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>
            {moment(m.createTime).format('DD.MM.yyyy HH:mm')}
          </div>
        </>
      ),
    },
    {
      dataField: 'type',
      text: 'Şirket Tipi',
      sort: true,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: AdminPanelCompanyDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>
            {m.type}

            <span className='text-muted fw-semibold text-muted d-block fs-7'>
              {m.operationAddressType}
            </span>
          </div>
        </>
      ),
    },
    {
      dataField: 'descriptionForAdvisor',
      text: 'Durum',
      sort: true,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: AdminPanelCompanyDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>{m.descriptionForAdvisor}</div>
          <div className='progress h-6px w-100'>
            <CompanyStateProgressBar percentage={m.progressPercentage ?? 0} />
          </div>
        </>
      ),
    },
    {
      dataField: 'totalPaymentAmount',
      text: 'Ödeme',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: AdminPanelCompanyDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.totalPaymentAmount?.toFixed(0)} ₺
          </div>
          {(m.totalPaymentAmountDetails?.length ?? 0) > 1 &&
            m.totalPaymentAmountDetails?.map((d) => {
              return (
                <div className='text-dark fs-8 row' key={d.name}>
                  <div className='col-6'>{d.name} </div>
                  <div className='col-6 text-end'>{d.amount?.toFixed(0)} ₺</div>
                </div>
              )
            })}
        </>
      ),
    },
    {
      dataField: 'totalDuePaymentAmount',
      text: 'Gelecek Ödeme',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: AdminPanelCompanyDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.totalDuePaymentAmount?.toFixed(0)} ₺
          </div>
          {(m.totalDuePaymentAmountDetails?.length ?? 0) > 1 &&
            m.totalDuePaymentAmountDetails?.map((d) => {
              return (
                <div className='text-dark fs-8 row' key={d.name}>
                  <div className='col-6'>{d.name} </div>
                  <div className='col-6 text-end'>{d.amount?.toFixed(0)} ₺</div>
                </div>
              )
            })}
        </>
      ),
    },
    {
      dataField: 'totalDept',
      text: 'Borç',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: AdminPanelCompanyDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>{m.totalDept?.toFixed(0)} ₺</div>
          {(m.totalDeptDetails?.length ?? 0) > 1 &&
            m.totalDeptDetails?.map((d) => {
              return (
                <div className='text-dark fs-8 row' key={d.name}>
                  <div className='col-6'>{d.name} </div>
                  <div className='col-6 text-end'>{d.amount?.toFixed(0)} ₺</div>
                </div>
              )
            })}
        </>
      ),
    },
    {
      dataField: '',
      text: 'Detay',
      sort: true,
      headerStyle: {width: '5%'},
      formatter: (cell: any, m: AdminPanelCompanyDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='d-flex justify-content-center flex-shrink-0'>
            {role == UserTypeEnum.Admin || role == UserTypeEnum.Satışçı ? (
              <>
                <AdminCompanyDetailPopup companyGuidId={m.guid ?? ''} />
                <a
                  href={m.contractDocumentUrl ?? ''}
                  target='_blank'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  title='Sözleşme İndir'
                >
                  <i className='fa-solid fa-file-contract'></i>
                </a>
              </>
            ) : (
              <AssignedCompanyPopup companyGuidId={m.guid ?? ''} />
            )}
          </div>
        </>
      ),
    },
  ]

  function getExcelData() {
    return (_.orderBy(props.companies, (o) => o.createTime, 'desc') ?? []).map((m) => {
      return {
        'Şirket Adı': m.shortName,
        'Şirket Tipi': m.type,
        'Kullanıcı Adı': m.firstName,
        'Kullanıcı Soyadı': m.lastName,
        Telefon: m.phoneNumber,
        Email: m.mailAddress,
        'Kayıt Tarihi': m.createTime,
        'Operasyon Adres Tipi': m.operationAddressType,
        'Şirket Durumu': m.descriptionForAdvisor,
        'Toplam Ödeme': m.totalPaymentAmount,
        'Toplam Gelecek Ödeme': m.totalDuePaymentAmount,
        'Toplam Borç': m.totalDept,
        'Aktif Mi': m.isCompanyActive,
        'Mali Müşavir': m.assignedFinancialAdvisor,
        Referans: m.referenceName,
        ...m.totalPaymentAmountDetails?.reduce(
          (a, v) => ({...a, [v.name + ' Ödeme']: v.amount?.toFixed(0)}),
          {}
        ),
        ...m.totalDuePaymentAmountDetails?.reduce(
          (a, v) => ({...a, [v.name + ' Gelecek Ödeme']: v.amount?.toFixed(0)}),
          {}
        ),
        ...m.totalDeptDetails?.reduce(
          (a, v) => ({...a, [v.name + ' Borç']: v.amount?.toFixed(0)}),
          {}
        ),
        ...m.averageRecurringPaymentDetails?.reduce(
          (a, v) => ({...a, [v.name + ' Aylık Ödeme']: v.amount?.toFixed(0)}),
          {}
        ),
      }
    })
  }

  const rowClasses = (row: AdminPanelCompanyDTO, rowIndex: number): string => {
    return clsx({
      'bg-danger bg-opacity-15': !row.isCompanyActive,
      'bg-warning bg-opacity-15':
        row.isCompanyActive &&
        row.suspensionStatusId == CompanySuspensionStatusEnum.Şirketi_Kapatmak_İstiyor,
    })
  }

  return (
    <>
      <div className={`card card-stretch`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5 d-flex justify-content-center'>
          <h3 className='card-title flex-column'>
            <div className='fw-bolder fs-1 text-center py-5'>Firmalar</div>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}

        <div className='card-body py-3'>
          <div className='table-responsive'>
            {(props.companies?.length ?? 0) > 0 && (
              <>
                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    totalSize: props?.companies?.length,
                    sizePerPage: 50,
                    paginationSize: 50,
                    paginationTotalRenderer: (from, to, size) => (
                      <span className='react-bootstrap-table-pagination-total ms-5'>
                        Toplam {size} firmadan {from} - {to} arası gösteriliyor{' '}
                        <ExportCSV csvData={getExcelData()} fileName={'test'} />
                      </span>
                    ),
                  })}
                >
                  {({paginationProps, paginationTableProps}) => (
                    <>
                      <div>
                        <SizePerPageDropdownStandalone {...paginationProps} />
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                        <BootstrapTable
                          {...paginationTableProps}
                          rowClasses={rowClasses}
                          keyField='index'
                          data={_.orderBy(props.companies, (o) => o.createTime, 'desc') ?? []}
                          columns={columns}
                          classes='table table-hover table-row-dashed table-rounded mt-5'
                        />
                      </div>
                    </>
                  )}
                </PaginationProvider>
              </>
            )}
          </div>

          {/* begin::Table container */}

          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default CompaniesTable
