import {useSelector} from 'react-redux'
import {getAdminState} from '../../../redux/adminSlice'
import _ from 'lodash'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import {ReportEnum} from '../../../types/ReportEnum'
import AdminSaleReport from './components/AdminSaleReport'
import CommissionReport from './components/CommissionReport'

export interface AdminCompaniesFilter {
  textFilter: string
  companyType: number
}

function AdminReportsPage() {
  const adminState = useSelector(getAdminState)

  return (
    <div className={'w-100'}>
      {adminState.reports?.reportEnum === ReportEnum.SaleReport && <AdminSaleReport />}
      {adminState.reports?.reportEnum === ReportEnum.ReferenceCommissionReport && (
        <CommissionReport />
      )}
    </div>
  )
}

export default AdminReportsPage
