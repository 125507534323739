import {useDispatch, useSelector} from 'react-redux'
import {getAdvisorState, loadAssingedCompanyAsync} from '../../../redux/advisorSlice'
import {ErrorMessage, Field, Form, Formik, FormikValues} from 'formik'
import {
  AssignedCompanyDTO,
  BooleanApiData,
  ServiceProviderUpdateCompanyRequest,
} from '../../../api/data-contracts'
import * as Yup from 'yup'
import {postRequestAsync, setIsLoading} from '../../../redux/commonSlice'
import {Api_ServiceProvider_UpdateCompany} from '../../../apiClient/apiClient'
import _ from 'lodash'
import FormikForm from '../../../components/form/FormikForm'
import FormInput from '../../../components/form/FormInput'
import FormCheckboxInput from '../../../components/form/FormCheckboxInput'
function AssignedCompanyTaxNumber() {
  const dispatch = useDispatch()
  const advisorState = useSelector(getAdvisorState)

  const schema = Yup.object({
    taxNumber: Yup.number()
      .min(0, 'Vergi Numarası 10 haneli olmalıdır.')
      .max(9999999999, 'Vergi Numarası 10 haneli olmalıdır.')
      .required('Vergi Numarası Zorunludur'),
  })

  const initData: ServiceProviderUpdateCompanyRequest = {
    taxNumber: advisorState.assignedCompay?.company?.taxAdministrationNumber ?? '',
    companyId: advisorState.assignedCompay?.company?.guid ?? '',
    isInspectionApproved: advisorState.assignedCompay?.company?.isInspectionApproved ?? false,
  }

  const submit = async (values: ServiceProviderUpdateCompanyRequest, actions: FormikValues) => {
    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_ServiceProvider_UpdateCompany,
        body: values,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        await dispatch<any>(
          loadAssingedCompanyAsync({companyId: advisorState.assignedCompay?.company?.guid ?? ''})
        )
      })
  }

  return (
    <FormikForm initialValues={initData} validationSchema={schema} onSubmit={submit}>
      {(props) => (
        <>
          <div className='row mb-7'>
            <label className='col-lg-3 fw-bold text-muted'>Vergi Numarası</label>

            <div className='col-lg-9 d-flex align-items-center'>
              <div className='w-75 row'>
                <div className='col-xl-4'>
                  <FormInput
                    istouched={props.touched.taxNumber ?? false}
                    errorMessage={props.errors.taxNumber}
                    fieldAttributes={{
                      type: 'text',
                      name: 'taxNumber',
                      autoComplete: 'off',
                      placeholder: '',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='separator border-3 my-3 mx-1'></div>

          <div className='row mb-7'>
            <label className='col-lg-3 fw-bold text-muted'>Yoklama Onay</label>

            <div className='col-lg-9 d-flex align-items-center'>
              <div className='w-75 row'>
                <div className='col-xl-4'>
                  <FormCheckboxInput
                    description=''
                    name='isInspectionApproved'
                    setFieldValue={props.setFieldValue}
                  />
                </div>
                <div className='col-xl-1 offset-7'>
                  <button type='submit' className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>Kaydet</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </FormikForm>
  )
}

export default AssignedCompanyTaxNumber
