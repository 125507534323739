import {loadAssingedCompanyAsync} from './advisorSlice'
import {
  BooleanApiData,
  DocumentBaseRequest,
  DocumentTypeDTO,
  FolderDTO,
  FolderDTOApiData,
  GetDocumentsRequest,
  RegisteredCardDTO,
  RegisteredCardDTOListApiData,
  UploadDocumentRequest,
} from './../api/data-contracts'
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AxiosResponse} from 'axios'
import {AddressFormDataDTO} from '../api/data-contracts'
import {
  Api_Document_DeleteDocument,
  Api_Document_GetDocuments,
  Api_Document_UploadDocument,
  Api_Payment_GetRegisteredCards,
} from '../apiClient/apiClient'
import {RootState} from './store'
import {setToken} from './userSlice'
import {defterdar_log} from '../utils/utilities'

export interface CommonState {
  isLoading: boolean
  isGeneralDataLoaded: boolean
  isSmsVerificationSendSuccess: boolean
  registeredCards: RegisteredCardDTO[] | null | undefined
  notificationToken: string | null | undefined
  currentStep: number
  areYouSureShowModal: boolean
  areYouSureModalAction: () => void
}

export interface FormData {
  companyStatus: string
  companyType: string
  companyShortName: string
  companyFullName: string
  country: number
  city: number
  district: number
  addressDetails: string
  operationAddressStatus: string //'1' | '2' | '3' | '4'
  workerCount: string
  rentAmount: string
  documentCount: number
  idNumber: string
  birthDate: string
  virtualOfficeCityId: number
  virtualOfficeDistrictId: number
  nameOnCard: string
  cardNumber: string
  cardExpiryMonth: string
  cardExpiryYear: string
  cardCvv: string
  kosgeb: boolean
  advisorSelection: string
}

const initialState: CommonState = {
  isLoading: false,
  isGeneralDataLoaded: false,
  isSmsVerificationSendSuccess: false,
  registeredCards: null,
  notificationToken: null,
  currentStep: 1,
  areYouSureShowModal: false,
  areYouSureModalAction: () => {},
}

export const comomonSlice = createSlice({
  name: 'common',
  initialState: initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setIsGeneralDataLoaded: (state, action: PayloadAction<boolean>) => {
      state.isGeneralDataLoaded = action.payload
    },
    setIsSmsVerificationSendSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSmsVerificationSendSuccess = action.payload
    },
    setRegisteredCards: (state, action: PayloadAction<RegisteredCardDTO[] | null | undefined>) => {
      state.registeredCards = action.payload
    },
    setNotificationToken: (state, action: PayloadAction<string | null | undefined>) => {
      state.notificationToken = action.payload
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload
    },
    nextStep: (state) => {
      state.currentStep = state.currentStep + 1
    },
    previousStep: (state) => {
      state.currentStep = state.currentStep - 1
    },
    openAreYouSureModal: (state, action: PayloadAction<any>) => {
      state.areYouSureModalAction = action.payload
      state.areYouSureShowModal = true
    },
    closeAreYouSureModal: (state) => {
      state.areYouSureShowModal = false
    },
  },
})

export const postRequestAsync = createAsyncThunk(
  'api/postRequest',
  async (
    payload: {
      postRequestFunction: (request: any, token: string) => Promise<AxiosResponse>
      body: any
      dontHandleIsLoading?: boolean
    },
    thunkAPI
  ) => {
    if (!payload.dontHandleIsLoading) {
      thunkAPI.dispatch(setIsLoading(true))
    }
    const userState = (thunkAPI.getState() as RootState).user
    let response: AxiosResponse<any, any>
    response = await payload.postRequestFunction(payload.body, userState.token ?? '')

    defterdar_log({
      name: payload.postRequestFunction.name,
      request: payload.body,
      response: response,
    })

    if (response.status === 401) {
      thunkAPI.dispatch(setToken(undefined))
    }
    if (!payload.dontHandleIsLoading) {
      thunkAPI.dispatch(setIsLoading(false))
    }
    return response.data
  }
)

export const getRequestAsync = createAsyncThunk(
  'api/getRequest',
  async (
    payload: {
      getRequestFunction: (token: string) => Promise<AxiosResponse>
    },
    thunkAPI
  ) => {
    thunkAPI.dispatch(setIsLoading(true))
    const userState = (thunkAPI.getState() as RootState).user
    const response = await payload.getRequestFunction(userState.token ?? '')

    defterdar_log({
      name: payload.getRequestFunction.name,
      response: response,
    })

    if (response.status === 401) {
      thunkAPI.dispatch(setToken(undefined))
    }
    thunkAPI.dispatch(setIsLoading(false))

    return response.data
  }
)


export const loadRegisteredCardsAsync = createAsyncThunk(
  'api/loadRegisteredCardsAsync',
  async (payload, thunkAPI) => {
    thunkAPI
      .dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_Payment_GetRegisteredCards,
          body: {},
        })
      )
      .unwrap()
      .then(async function (response: RegisteredCardDTOListApiData) {
        await thunkAPI.dispatch<any>(setRegisteredCards(response.data))
      })
  }
)

export const {
  setIsLoading,
  setIsSmsVerificationSendSuccess,
  setIsGeneralDataLoaded,
  setRegisteredCards,
  setNotificationToken,
  nextStep,
  previousStep,
  setStep,
  openAreYouSureModal,
  closeAreYouSureModal,
} = comomonSlice.actions
export default comomonSlice.reducer

export const getCommonState = (state: RootState) => state.common
