import React from 'react'

function TextWithClipBoard(props: {text: string}) {
  return (
    <div className='text-center'>
      {props.text}
      <a
        href='#'
        className='btn btn-icon btn-white btn-sm'
        onClick={() => {
          navigator.clipboard.writeText(props.text)
        }}
      >
        <i className='far fa-paste fs-2 text-info'></i>
      </a>
    </div>
  )
}

export default TextWithClipBoard
