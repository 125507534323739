import React from 'react'
import HomePageBanners from './HomePageBanners'
import HomePageCalendar from './HomePageCalendar'
import ContractDocument from './ContractDocument'

function AdminSettingsPage() {
  return (
    <div className='card card-border w-100 d-flex flex-row-fluid flex-center h-100 min-h-800px'>
      <div className='card-header card-header-stretch'>
        <div className='card-toolbar'>
          <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-4 fw-bold border-0'>
            <li className='nav-item'>
              <a
                className={'nav-link active'}
                data-bs-toggle='tab'
                href={'#kt_tab_panel_home_banner'}
              >
                Anasayfa Banner
              </a>
            </li>
            <li className='nav-item'>
              <a className={'nav-link'} data-bs-toggle='tab' href={'#kt_tab_panel_calendar'}>
                Anasayfa Takvim
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={'nav-link'}
                data-bs-toggle='tab'
                href={'#kt_tab_panel_contract_document'}
              >
                Sözleşme Dosyası Takvim
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='card-body pt-0 fs-6 w-100 pt-1'>
        <div className='mb-3 mt-2'></div>

        <div className='tab-content' id='myTabContent'>
          <div
            className={'tab-pane fade active show'}
            id={'kt_tab_panel_home_banner'}
            role='tabpanel'
          >
            <HomePageBanners />
          </div>
          <div className={'tab-pane fade'} id={'kt_tab_panel_calendar'} role='tabpanel'>
            <HomePageCalendar />
          </div>
          <div className={'tab-pane fade'} id={'kt_tab_panel_contract_document'} role='tabpanel'>
            <ContractDocument />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminSettingsPage
