import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {Provider} from 'react-redux'
import {store} from './app/redux/store'
import {CookiesProvider} from 'react-cookie'
import {ToastContainer} from 'react-toastify'
import {BrowserRouter} from 'react-router-dom'
import * as Sentry from '@sentry/react'

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
Chart.register(...registerables)

Sentry.init({
  dsn: 'https://6d16d68077b358422294de3253b9d7f3@o4507803505131520.ingest.de.sentry.io/4507803508211792',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'localhost:3011',
    'https://test.defterdar.com/',
    'https://admintest.defterdar.com/',
    'https://admin.defterdar.com/',
    'https://app.defterdar.com/',
    /^https:\/\/localhost/,
  ],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// module.exports = function (app: {use: (arg0: (req: any, res: any, next: any) => void) => void}) {
//   app.use(function (req, res, next) {
//     res.setHeader('Document-Policy', 'js-profiling')
//     next()
//   })
// }

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <CookiesProvider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <MetronicI18nProvider>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </MetronicI18nProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
      <ToastContainer />
    </CookiesProvider>
  )
}
