import React, {useEffect, useState} from 'react'
import {SaleReportData, SaleReportDataListApiData} from '../../../../api/data-contracts'
import {Api_Admin_GetSaleReport} from '../../../../apiClient/apiClient'
import {getAdminState, setDashboardData} from '../../../../redux/adminSlice'
import {postRequestAsync, setIsLoading} from '../../../../redux/commonSlice'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import moment from 'moment'
import ExportCSV from '../../Components/ExportCSV'

function AdminSaleReport() {
  const adminState = useSelector(getAdminState)
  const columns: ColumnDescription[] = [
    {
      dataField: 'reportIndex',
      text: 'Tarih',
      sort: true,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: SaleReportData, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>
            {moment(new Date(m.reportYear ?? 0, (m.reportMonth ?? 0) - 1)).format('MMMM yyyy')}
          </div>
        </>
      ),
    },
    {
      dataField: 'newCustomer',
      text: 'Yeni Müşteri',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: SaleReportData, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>{m.newCustomer?.toFixed(0)}</div>
        </>
      ),
    },

    {
      dataField: 'suspendedCustomer',
      text: 'Fesih Müşteri',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: SaleReportData, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.suspendedCustomer?.toFixed(0)}
          </div>
        </>
      ),
    },

    {
      dataField: 'totalAmount',
      text: 'Toplam',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: SaleReportData, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.totalAmount?.toFixed(0)} ₺
          </div>
        </>
      ),
    },
    {
      dataField: 'totalNewAmount',
      text: 'Toplam Yeni',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: SaleReportData, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.totalNewAmount?.toFixed(0)} ₺
          </div>
        </>
      ),
    },
    {
      dataField: 'totalSubsAmount',
      text: 'Toplam Abonelik',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: SaleReportData, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.totalSubsAmount?.toFixed(0)} ₺
          </div>
        </>
      ),
    },

    {
      dataField: 'defterdarAmount',
      text: 'D. Toplam',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: SaleReportData, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.defterdarAmount?.toFixed(0)} ₺
          </div>
        </>
      ),
    },
    {
      dataField: 'defterdarNewAmount',
      text: 'D. Yeni',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: SaleReportData, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.defterdarNewAmount?.toFixed(0)} ₺
          </div>
        </>
      ),
    },
    {
      dataField: 'defterdarSubsAmount',
      text: 'D. Abonelik',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: SaleReportData, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.defterdarSubsAmount?.toFixed(0)} ₺
          </div>
        </>
      ),
    },
  ]

  function getExcelData() {
    return (
      _.orderBy(adminState?.reports.saleReport, ['reportYear', 'reportMonth'], ['desc', 'desc']) ??
      []
    ).map((m) => {
      return {
        Index: m.reportIndex,
        'Rapor Yılı': m.reportYear,
        'Rapor Ayı': m.reportMonth,
        'Yeni Müşteri': m.newCustomer,
        'Fesih Müşteri': m.suspendedCustomer,
        'Defterdar Tutarı': m.defterdarAmount,
        'Defterdar Yeni Tutar': m.defterdarNewAmount,
        'Defterdar Abone Tutar': m.defterdarSubsAmount,
        'Toplam Tutar': m.totalAmount,
        'Toplam Yeni Tutar': m.totalNewAmount,
        'Toplam Abone Tutar': m.totalSubsAmount,
      }
    })
  }

  return (
    <div className={`card card-stretch`}>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <div>
            <div className='text-end'>
              <ExportCSV csvData={getExcelData()} fileName={'Satış Raporu'} />
            </div>
            {(adminState?.reports.saleReport?.length ?? 0) > 0 && (
              <BootstrapTable
                keyField='reportIndex'
                data={
                  _.orderBy(
                    adminState?.reports.saleReport,
                    ['reportYear', 'reportMonth'],
                    ['desc', 'desc']
                  ) ?? []
                }
                columns={columns}
                classes='table table-hover table-row-dashed table-rounded mt-5'
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminSaleReport
