/* eslint-disable react/jsx-no-target-blank */
import {KTSVG} from '../../../../../_metronic/helpers'
import {SidebarMenuItem} from '../SidebarMenuItem'
import {getUserState} from '../../../../redux/userSlice'
import {useDispatch, useSelector} from 'react-redux'
import {getCompanyState} from '../../../../redux/companySlice'
import {
  getCommonState,
  getRequestAsync,
  postRequestAsync,
  setIsLoading,
} from '../../../../redux/commonSlice'
import {getAdvisorState, setAssignedCompanies} from '../../../../redux/advisorSlice'
import _ from 'lodash'
import {useEffect, useState} from 'react'
import {
  AdminPanelCompaniesStartupDTO,
  AdminPanelCompaniesStartupDTOApiData,
  AdminPanelCompanyDTOIEnumerableApiData,
  CompanyDTO,
  GetAdminCompaniesRequest,
} from '../../../../api/data-contracts'
import {
  Api_Admin_GetCompanies,
  Api_ServiceProvider_GetCompanies,
  Api_ServiceProvider_GetCompaniesStartup,
} from '../../../../apiClient/apiClient'
import moment from 'moment'
import {Formik, Form, Field} from 'formik'
import {defterdar_log, DropdownOption} from '../../../../utils/utilities'
import DateRangeSelector from '../../../form/dropdown/DateRangeSelector'
import Select, {ClearIndicatorProps} from 'react-select'

const SidebarAdvisorMenu = () => {
  const userState = useSelector(getUserState)
  const companyState = useSelector(getCompanyState)
  const commonState = useSelector(getCommonState)
  const advisorState = useSelector(getAdvisorState)

  const [startUp, setStartUp] = useState<AdminPanelCompaniesStartupDTO | null | undefined>(null)

  const dispatch = useDispatch()

  const submit = async (values: GetAdminCompaniesRequest) => {
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_ServiceProvider_GetCompanies,
        body: values,
      })
    )
      .unwrap()
      .then(async function (response: AdminPanelCompanyDTOIEnumerableApiData) {
        dispatch<any>(setAssignedCompanies(response.data))
      })
  }

  async function loadStartup() {
    await dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_ServiceProvider_GetCompaniesStartup,
      })
    )
      .unwrap()
      .then(async function (response: AdminPanelCompaniesStartupDTOApiData) {
        setStartUp(response.data)
      })

    await submit(initFilterValues)
  }

  useEffect(() => {
    loadStartup()
  }, [])

  const activePassiveOptions: DropdownOption[] = [
    {
      label: 'Hepsi',
      value: '-1',
    },
    {
      label: 'Fesih',
      value: '0',
    },
    {
      label: 'Aktif',
      value: '1',
    },
  ]

  const initFilterValues: GetAdminCompaniesRequest = {
    textSearch: '',
    creationDateEnd: moment(new Date()).startOf('month').add(1, 'month').format('yyyy-MM-DD'),
    creationDateStart: moment(new Date()).add(-10, 'year').startOf('month').format('yyyy-MM-DD'),
    activePassive: -1,
    assignedFinancialAdvisorUserId: undefined,
    deptAmount: undefined,
    boughtProducts: [],
    companyTypes: [],
    states: [],
  }

  return (
    <>
      <div className='menu-item'>
        <div className='menu-link'>
          <label className='menu-title'>Defterdar Kodunuz: {userState?.data?.displayNumber}</label>
        </div>
      </div>
      <>
        <Formik initialValues={initFilterValues} onSubmit={submit}>
          {({values, setFieldValue, status, resetForm}) => {
            return (
              <Form noValidate id='admin_company_form' placeholder={undefined}>
                {/* <div className='row text-white'>{JSON.stringify(values)}</div> */}

                <div className='row'>
                  <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                    <button type='submit' className='btn btn-lg btn-primary me-3 col-12'>
                      <span className='indicator-label'>Filtrele</span>
                    </button>
                  </div>
                </div>

                <div className='row'>
                  <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                    <a
                      className='btn btn-lg btn-primary me-3 col-12'
                      onClick={() => {
                        resetForm({values: initFilterValues})
                      }}
                    >
                      <span className='indicator-label'>Temizle</span>
                    </a>
                  </div>
                </div>

                <div className='row'>
                  <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                      <span className=''>Arama</span>
                    </label>
                    <Field
                      type='text'
                      name='textSearch'
                      className='form-control form-control-solid fs-8'
                      placeholder='Filtre'
                    />
                  </div>

                  <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                      <span className=''>Kayıt Tarihi Aralığı</span>
                    </label>
                    <DateRangeSelector
                      setFieldValue={setFieldValue}
                      finishKey='creationDateEnd'
                      startKey='creationDateStart'
                    />
                  </div>

                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                    <span className=''>Aktif - Fesih</span>
                  </label>
                  <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                    <Select
                      options={activePassiveOptions}
                      closeMenuOnSelect={true}
                      name='activePassive'
                      onChange={(value: any) => {
                        defterdar_log(value)
                        setFieldValue('activePassive', parseInt((value as DropdownOption).value))
                      }}
                      classNames={{
                        control: (state) => 'form-select form-select-solid fs-8',
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          paddingTop: '0',
                          paddingBottom: '0',
                        }),
                        dropdownIndicator: (baseStyles, state) => ({
                          ...baseStyles,
                          display: 'none',
                        }),
                      }}
                    />
                  </div>

                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                    <span className=''>Durum</span>
                  </label>
                  <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                    <Select
                      // loadOptions={loadOptions}
                      options={
                        _.orderBy(startUp?.states, (o) => o.progressIndex)?.map((m) => {
                          const d: DropdownOption = {
                            label:
                              (m.progressIndex + ' - ' + m.description ?? '') +
                              ' (' +
                              advisorState?.assignedCompanies?.filter(
                                (f) => f.stepDescription == m.description
                              ).length +
                              ')',
                            value: m.applicationStepId?.toString() ?? '0',
                          }
                          return d
                        }) ?? []
                      }
                      isMulti
                      closeMenuOnSelect={false}
                      name='states'
                      onChange={(value) => {
                        setFieldValue(
                          'states',
                          (value as DropdownOption[]).map((m) => Number(m.value))
                        )
                      }}
                      classNames={{
                        control: (state) => 'form-select form-select-solid fs-8',
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          paddingTop: '0',
                          paddingBottom: '0',
                        }),
                        dropdownIndicator: (baseStyles, state) => ({
                          ...baseStyles,
                          display: 'none',
                        }),
                      }}
                    />
                  </div>

                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                    <span className=''>Şirket Tipi</span>
                  </label>
                  <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                    <Select
                      options={
                        startUp?.companyTypes?.map((m) => {
                          const d: DropdownOption = {
                            label:
                              (m.description ?? '') +
                              ' (' +
                              advisorState?.assignedCompanies?.filter(
                                (f) => f.type == m.description
                              ).length +
                              ')',
                            value: m.companyTypeId?.toString() ?? '0',
                          }
                          return d
                        }) ?? []
                      }
                      isMulti
                      closeMenuOnSelect={false}
                      name='companyTypes'
                      onChange={(value) => {
                        setFieldValue(
                          'companyTypes',
                          (value as DropdownOption[]).map((m) => Number(m.value))
                        )
                      }}
                      classNames={{
                        control: (state) => 'form-select form-select-solid fs-8',
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          paddingTop: '0',
                          paddingBottom: '0',
                        }),
                        dropdownIndicator: (baseStyles, state) => ({
                          ...baseStyles,
                          display: 'none',
                        }),
                      }}
                    />
                  </div>

                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                    <span className=''>Fesih Durum</span>
                  </label>
                  <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                    <Select
                      options={
                        startUp?.suspensionStates?.map((m) => {
                          const d: DropdownOption = {
                            label:
                              (m.description ?? '') +
                              ' (' +
                              advisorState?.assignedCompanies?.filter(
                                (f) => f.suspensionStatusId == m.id
                              ).length +
                              ')',
                            value: m.id?.toString() ?? '0',
                          }
                          return d
                        }) ?? []
                      }
                      isMulti
                      closeMenuOnSelect={false}
                      name='suspensionStates'
                      onChange={(value) => {
                        setFieldValue(
                          'suspensionStates',
                          (value as DropdownOption[]).map((m) => Number(m.value))
                        )
                      }}
                      classNames={{
                        control: (state) => 'form-select form-select-solid fs-8',
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          paddingTop: '0',
                          paddingBottom: '0',
                        }),
                        dropdownIndicator: (baseStyles, state) => ({
                          ...baseStyles,
                          display: 'none',
                        }),
                      }}
                    />
                  </div>

                  <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                      <span className=''>Borç</span>
                    </label>
                    <Field
                      type='number'
                      name='deptAmount'
                      className='form-control form-control-solid fs-8'
                      placeholder=''
                      onChange={(event: any) => {
                        setFieldValue('deptAmount', Number(event.target.value))
                      }}
                    />
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </>
    </>
  )
}

export {SidebarAdvisorMenu}
