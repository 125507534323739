import {useSelector} from 'react-redux'
import {getAdminState} from '../../../redux/adminSlice'
import _ from 'lodash'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import CompaniesTable from '../../../components/admin/CompaniesTable'

export interface AdminCompaniesFilter {
  textFilter: string
  companyType: number
}

function AdminCompaniesPage() {
  const adminState = useSelector(getAdminState)

  return <CompaniesTable companies={adminState.companies ?? []} />
}

export default AdminCompaniesPage
