import React from 'react'
import RadioButtonWrapper from './RadioButtonWrapper'
import Translator from '../../common/translate/Translator'

function SmallRadioButton(props: {
  description: string
  name: string
  value: any
  setFieldValue?: any
  valueConverter?: any
  onChange?: any
  descriptionParams?: any
}) {
  return (
    <>
      <div className='form-check form-check-custom form-check-solid form-check-lg mt-3'>
        <RadioButtonWrapper
          fieldAttributes={{
            className: 'form-check-input',
            name: props.name,
            value: props.value,
            onChange: props.onChange,
          }}
          setFieldValue={props.setFieldValue}
          valueConverter={props.valueConverter ?? parseInt}
        />
        <label className='form-check-label' htmlFor='advisorSelect'>
          <Translator name={props.description} params={props.descriptionParams} />
        </label>
      </div>
    </>
  )
}

export default SmallRadioButton
