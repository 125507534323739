/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../../_metronic/partials'
import {GraphData} from '../../../../api/data-contracts'
import _ from 'lodash'
import {getAdminState} from '../../../../redux/adminSlice'
import {useSelector} from 'react-redux'
import moment from 'moment'
import 'moment/locale/tr'

const PaymentDashboardGraph: React.FC = () => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const adminState = useSelector(getAdminState)

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const labelColor = getCSSVariableValue('--kt-gray-800')
    const baseColor = getCSSVariableValue('--kt-success')
    const lightColor = getCSSVariableValue('--kt-success-light')

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(
        height,
        labelColor,
        baseColor,
        lightColor,
        adminState.dashboardData?.weeklyPaymentGraphData ?? []
      )
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, adminState.dashboardData?.weeklyPaymentGraphData])

  return (
    <div className={`card card-xl-stretch mb-xl-8`}>
      {/* begin::Body */}
      <div className='card-body d-flex flex-column p-0'>
        <div className='d-flex flex-stack flex-grow-1 card-p'>
          <div className='d-flex flex-column me-2'>
            <a href='#' className='text-dark text-hover-primary fw-bold fs-3'>
              Ödeme
            </a>

            <span className='text-muted fw-semibold mt-1'>Haftalık Ödeme Grafiği</span>
          </div>

          {/* <span className='symbol symbol-50px'>
            <span className={`symbol-label fs-5 fw-bold bg-light-success text-success`}>100</span>
          </span> */}
        </div>
        <div
          ref={chartRef}
          className='statistics-widget-3-chart card-rounded-bottom'
          style={{height: '150px'}}
        ></div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {PaymentDashboardGraph}

function getChartOptions(
  height: number,
  labelColor: string,
  baseColor: string,
  lightColor: string,
  graphData: GraphData[]
): ApexOptions {
  const options: ApexOptions = {
    series: [
      {
        name: 'Ödeme',
        data: graphData.map((m) => m.value ?? 0),
      },
    ],
    yaxis: {
      min: 0,
      max: (_.max(graphData.map((m) => m.value ?? 0)) ?? 0) + 5000,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: height,

      sparkline: {
        enabled: true,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},

    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: graphData.map((m) => moment(m.date).locale('tr').format('DD-MMM-yyyy')),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: '#E4E6EF',
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },

    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' ₺'
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [baseColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
    dataLabels: {
      enabled: true,
      offsetY: -4,
      background: {
        borderRadius: 5,
        borderColor: baseColor,
        foreColor: baseColor,
        dropShadow: {},
      },
      formatter(val, opts) {
        return (Number(val) / 1000).toFixed(0) + ' K'
      },
    },
  }

  return options
}
