import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {BooleanApiData, SetCompanyStepRequest} from '../../../api/data-contracts'
import {Api_Company_SetCompanyStep} from '../../../apiClient/apiClient'
import {getCommonState, postRequestAsync, setIsLoading} from '../../../redux/commonSlice'
import {getAdvisorState, loadAssingedCompanyAsync} from '../../../redux/advisorSlice'
import clsx from 'clsx'
import {Button, Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {Guid} from 'guid-typescript'
import {ApplicationStepEnum} from '../../../apiClient/enums'
import {useRowState} from 'react-table'
import {getUserState} from '../../../redux/userSlice'
import {getCompanyState} from '../../../redux/companySlice'

function CompanyStatusComponent(props: {
  iconName: string
  iconMessage: string
  iconColoring: string
  targerStepId?: number
  visibleSteps?: number[]
  enabledSteps?: number[]
  enabledRoles?: number[]
  stepId?: number
  isDisabled?: boolean
}) {
  const dispatch = useDispatch()
  const advisorState = useSelector(getAdvisorState)
  const commonState = useSelector(getCommonState)
  const userState = useSelector(getUserState)
  const companyState = useSelector(getCompanyState)

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => {
    if ((props.targerStepId ?? 0) > 0 && isEnabled) {
      setShow(true)
    }
  }

  function changeStep() {
    if ((props.targerStepId ?? 0) > 0 && isEnabled) {
      const body: SetCompanyStepRequest = {
        companyId: advisorState.assignedCompay?.company?.guid ?? '',
        stepId: props.targerStepId,
      }
      dispatch(setIsLoading(true))
      dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_Company_SetCompanyStep,
          body: body,
        })
      )
        .unwrap()
        .then(async (response: BooleanApiData) => {
          await dispatch<any>(
            loadAssingedCompanyAsync({companyId: advisorState.assignedCompay?.company?.guid ?? ''})
          )
          dispatch(setIsLoading(false))
          handleClose()
        })
    }
  }

  const roleId = userState?.data?.roleId
  const stepId =
    (roleId == 1
      ? companyState?.data?.company?.stepId
      : advisorState.assignedCompay?.company?.stepId) ?? 0

  const isEnabled =
    (props.enabledSteps?.includes(stepId) ?? true) &&
    (props.enabledRoles?.includes(userState?.data?.roleId ?? 0) ?? true) &&
    advisorState.assignedCompay?.company?.isActive === true && !props.isDisabled

  if (
    ((props.visibleSteps?.length ?? 0) > 0 && (props.visibleSteps?.includes(stepId) ?? false)) ||
    (props.visibleSteps?.length ?? 0) == 0
  ) {
    return (
      <div className={'d-flex flex-column ' + (isEnabled ? '' : 'opacity-25')}>
        {
          <Modal show={show} onHide={handleClose} centered id={'kt_modal_' + Guid.create()}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <p>Firmanın durumunu değiştirmek istediğinizden emin misiniz?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                onClick={handleClose}
              >
                İptal
              </Button>
              <Button
                type='button'
                data-kt-indicator={commonState?.isLoading ? 'on' : ''}
                onClick={changeStep}
                className='btn btn-primary'
              >
                <span className='indicator-label'>Kaydet</span>

                <span className='indicator-progress'>
                  Kaydediliyor...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </Button>
            </Modal.Footer>
          </Modal>
        }

        <div className='d-flex align-items-center mb-2' onClick={handleShow}>
          <div
            className={clsx(
              'btn btn-link mb-2 ',
              props.stepId == advisorState.assignedCompay?.company?.stepId || isEnabled
                ? props.iconColoring
                : '',
              (props?.targerStepId ?? 0) > 0 ? 'mt-5' : ''
            )}
          >
            <i className={props.iconName + ' me-2'}></i>
            <div className='fs-6'>{props.iconMessage}</div>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default CompanyStatusComponent
