import React, {ChangeEventHandler} from 'react'
import Translator from '../common/translate/Translator'
import {Field} from 'formik'

function FormCheckboxInput(props: {
  name: string
  description: string
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
  onChange?: (value: boolean) => void
}) {
  return (
    <div className='form-check form-check-custom form-check-solid'>
      <Field
        className='form-check-input'
        type='checkbox'
        name={props.name}
        id='flexCheckDefault'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.setFieldValue?.(props.name, e.target.checked)
          props.onChange?.(e.target.checked)
        }}
      />
      <label className='form-check-label' htmlFor='flexCheckDefault'>
        <Translator name={props.description} />
      </label>
    </div>
  )
}

export default FormCheckboxInput
