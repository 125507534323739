/* eslint-disable react/jsx-no-target-blank */
import {useDispatch, useSelector} from 'react-redux'
import {postRequestAsync, setIsLoading} from '../../../../redux/commonSlice'
import {getAdminState, setReport} from '../../../../redux/adminSlice'
import _ from 'lodash'
import {ReportEnum} from '../../../../types/ReportEnum'
import {SaleReportDataListApiData} from '../../../../api/data-contracts'
import {
  Api_Admin_GetReferenceCommissionReport,
  Api_Admin_GetSaleReport,
} from '../../../../apiClient/apiClient'
import ReportButton from './components/ReportButton'

const SidebarAdminReportsMenu = () => {
  const dispatch = useDispatch()

  const loadSaleReport = async () => {
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_GetSaleReport,
        body: {},
      })
    )
      .unwrap()
      .then(async function (response: SaleReportDataListApiData) {
        dispatch<any>(
          setReport({
            reportEnum: ReportEnum.SaleReport,
            saleReport: response.data,
          })
        )
      })
  }

  const loadReferenceCommissionReport = () => {
    dispatch<any>(
      setReport({
        reportEnum: ReportEnum.ReferenceCommissionReport,
      })
    )
  }

  return (
    <>
      <ReportButton loader={loadSaleReport} name='Satış Raporu' />
      <ReportButton loader={loadReferenceCommissionReport} name='Komisyon Raporu' />
    </>
  )
}

export {SidebarAdminReportsMenu}
