import React, {useEffect, useState} from 'react'
import DocViewer, {DocViewerRenderers, IDocument} from '@cyntler/react-doc-viewer'
import {
  Api_Admin_GetContractTemplate,
  Api_Admin_UploadContractDocument,
  Api_Document_UploadDocument,
} from '../../../apiClient/apiClient'
import {useDispatch} from 'react-redux'
import {getRequestAsync, postRequestAsync} from '../../../redux/commonSlice'
import {
  BooleanApiData,
  StringApiData,
  UploadContractDocumentRequest,
  UploadDocumentRequest,
} from '../../../api/data-contracts'
import {convertBase64} from '../../../utils/utilities'

function ContractDocument() {
  const dispatch = useDispatch()

  const [docs, setDocs] = useState<IDocument[]>([])

  function loadTemplate() {
    dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Admin_GetContractTemplate,
      })
    )
      .unwrap()
      .then(async function (response: StringApiData) {
        setDocs([
          {
            uri: response.data ?? '',
            fileType: 'docx',
          },
        ])
      })
  }

  useEffect(() => {
    loadTemplate()
  }, [])

  const hiddenFileInput = React.useRef<HTMLInputElement>(null)

  const handleUploadClick = (event: any) => {
    hiddenFileInput?.current?.click()
  }

  async function onFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length ?? 0 > 0) {
      const file = (event.target.files ?? [])[0]

      const base64 = await convertBase64(file)

      const request: UploadContractDocumentRequest = {
        base64Content: base64 as string,
      }

      await uploadDocument(request)

      event.target.value = ''
    }
  }

  async function uploadDocument(request: UploadContractDocumentRequest) {
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_UploadContractDocument,
        body: request,
      })
    )
      .unwrap()
      .then((response: BooleanApiData) => {
        loadTemplate()
      })
  }

  return (
    <div className='h-100'>
      <div className='d-flex justify-content-end' data-kt-filemanager-table-toolbar='base'>
        <input
          type='file'
          accept='.docx'
          className='form-control form-control-solid'
          // name={props.document?.description ?? ''}
          // data-id={props.document?.documentTypeId}
          onChange={onFileChange}
          ref={hiddenFileInput}
          style={{display: 'none'}}
        />
        <button type='button' className='btn btn-flex btn-primary' onClick={handleUploadClick}>
          Sözleşme Taslak Güncelle
        </button>
      </div>
      <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} className='h-600px' />
    </div>
  )
}

export default ContractDocument
