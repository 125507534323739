import {useSelector} from 'react-redux'
import {getAdminState} from '../../../redux/adminSlice'
import _ from 'lodash'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import DashboardCard from './components/DashboardCard'
import DashboardSingleCard from './components/DashboardSingleCard'
import {NewCustomerDashboardGraph} from './components/NewCustomerDashboardGraph'
import {PaymentDashboardGraph} from './components/PaymentDashboardGraph'

export interface AdminCompaniesFilter {
  textFilter: string
  companyType: number
}

function AdminDashboardPage() {
  const adminState = useSelector(getAdminState)

  return (
    <div>
      <div
        className='card card-border w-100 d-flex flex-row-fluid flex-center'
        style={{boxShadow: 'none'}}
      >
        <div className={'w-100'}>
          <div className='card-header card-header-stretch'>
            <div className='card-toolbar'>
              <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-4 fw-bold border-0'>
                <li className='nav-item'>
                  <a className={'nav-link active'} data-bs-toggle='tab' href={'#kt_tab_panel_1'}>
                    Global
                  </a>
                </li>
                <li className='nav-item'>
                  <a className={'nav-link'} data-bs-toggle='tab' href={'#kt_tab_panel_2'}>
                    Filtrelenmiş
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='card-body'>
            <div className='tab-content' id='myTabContent'>
              <div className={'tab-pane fade active show'} id={'kt_tab_panel_1'} role='tabpanel'>
                <div className='row g-5'>
                  {adminState.dashboardData?.cards
                    ?.filter((f) => f.isGlobal)
                    .map((f) => {
                      return <DashboardCard key={f.title} cardData={f} />
                    })}
                </div>
              </div>

              <div className={'tab-pane fade'} id={'kt_tab_panel_2'} role='tabpanel'>
                <div className='row g-5'>
                  {adminState.dashboardData?.cards
                    ?.filter((f) => !f.isGlobal)
                    .map((f) => {
                      return <DashboardCard key={f.title} cardData={f} />
                    })}
                </div>
                <div className='row g-5 g-xl-8'>
                  <div className='col-xl-6'>
                    <NewCustomerDashboardGraph />
                  </div>
                  <div className='col-xl-6'>
                    <PaymentDashboardGraph />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboardPage
