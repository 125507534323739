import {Field} from 'formik'
import moment from 'moment'
import React from 'react'

function DateRangeSelectorHorizontal(props: {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  startKey: string
  finishKey: string
}) {
  return (
    <>
      <div className='row'>
        <Field
          as='select'
          className='form-select form-select-solid col me-2'
          onChange={(event: any) => {
            if (event.target.value == 0) {
              props.setFieldValue(
                props.startKey,
                moment(new Date()).startOf('day').format('yyyy-MM-DD')
              )
              props.setFieldValue(
                props.finishKey,
                moment(new Date()).add(1, 'day').startOf('day').format('yyyy-MM-DD')
              )
            } else if (event.target.value == 1) {
              props.setFieldValue(
                props.startKey,
                moment(new Date()).startOf('week').format('yyyy-MM-DD')
              )
              props.setFieldValue(
                props.finishKey,
                moment(new Date()).startOf('week').add(1, 'week').format('yyyy-MM-DD')
              )
            } else if (event.target.value == 2) {
              props.setFieldValue(
                props.startKey,
                moment(new Date()).startOf('month').format('yyyy-MM-DD')
              )
              props.setFieldValue(
                props.finishKey,
                moment(new Date()).startOf('month').add(1, 'month').format('yyyy-MM-DD')
              )
            } else if (event.target.value == 3) {
              props.setFieldValue(
                props.startKey,
                moment(new Date()).startOf('year').format('yyyy-MM-DD')
              )
              props.setFieldValue(
                props.finishKey,
                moment(new Date()).endOf('year').format('yyyy-MM-DD')
              )
            } else if (event.target.value == 4) {
              props.setFieldValue(
                props.startKey,
                moment(new Date()).add(-1, 'month').startOf('month').format('yyyy-MM-DD')
              )
              props.setFieldValue(
                props.finishKey,
                moment(new Date()).startOf('month').format('yyyy-MM-DD')
              )
            } else if (event.target.value == 5) {
              props.setFieldValue(
                props.startKey,
                moment(new Date()).add(-10, 'year').startOf('year').format('yyyy-MM-DD')
              )
              props.setFieldValue(
                props.finishKey,
                moment(new Date()).endOf('day').format('yyyy-MM-DD')
              )
            } else {
              props.setFieldValue(
                props.startKey,
                moment(new Date())
                  .startOf('year')
                  .add(Number(event.target.value) - 11, 'month')
                  .format('yyyy-MM-DD')
              )
              props.setFieldValue(
                props.finishKey,
                moment(new Date())
                  .startOf('year')
                  .add(Number(event.target.value) - 11, 'month')
                  .endOf('month')
                  .format('yyyy-MM-DD')
              )
            }
          }}
        >
          <option value={-1} key={'-1'}>
            Hazır Tarih Aralıkları
          </option>
          <option value={0} key={'0'}>
            Bugün
          </option>
          <option value={1} key={'1'}>
            Bu Hafta
          </option>
          <option value={2} key={'2'}>
            Bu Ay
          </option>
          <option value={3} key={'3'}>
            Bu Yıl
          </option>
          <option value={4} key={'4'}>
            Geçen Ay
          </option>
          <option value={5} key={'5'}>
            Tüm Zamanlar
          </option>
          {Array.from({length: 12}, (_, i) => i + 1).map((m) => {
            return (
              <option value={10 + m} key={(10 + m).toString()}>
                {new Date(0, m - 1).toLocaleString('tr-TR', {month: 'long'})}{' '}
                {new Date().getFullYear()}
              </option>
            )
          })}
        </Field>
        <Field
          placeholder=''
          type='date'
          autoComplete='off'
          name={props.startKey}
          className='form-control me-2 col'
        />
        <Field
          placeholder=''
          type='date'
          autoComplete='off'
          name={props.finishKey}
          className='form-control me-2 col'
        />
      </div>
    </>
  )
}

export default DateRangeSelectorHorizontal
