/* eslint-disable react/jsx-no-target-blank */
import {useDispatch, useSelector} from 'react-redux'
import {postRequestAsync, setIsLoading} from '../../../../redux/commonSlice'
import {useEffect} from 'react'
import {Formik, Form} from 'formik'
import {getAdminState, setDashboardData} from '../../../../redux/adminSlice'
import {GetDashboardDataRequest, DashboardDataApiData} from '../../../../api/data-contracts'
import {Api_Admin_GetDashboard} from '../../../../apiClient/apiClient'
import _ from 'lodash'
import moment from 'moment'
import DateRangeSelector from '../../../form/dropdown/DateRangeSelector'

const SidebarAdminDashboardMenu = () => {
  const dispatch = useDispatch()
  const adminState = useSelector(getAdminState)

  const submit = async (values: GetDashboardDataRequest) => {
    dispatch(setIsLoading(true))

    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_GetDashboard,
        body: values,
      })
    )
      .unwrap()
      .then(async function (response: DashboardDataApiData) {
        dispatch<any>(setDashboardData(response.data))
        dispatch(setIsLoading(false))
      })
  }

  useEffect(() => {
    submit(initFilterValues)
  }, [])

  const initFilterValues: GetDashboardDataRequest = {
    start: moment(new Date()).startOf('month').format('yyyy-MM-DD'),
    finish: moment(new Date()).endOf('month').format('yyyy-MM-DD'),
  }

  return (
    <>
      <Formik initialValues={initFilterValues} onSubmit={submit}>
        {({values, setFieldValue, status, resetForm, setValues}) => {
          return (
            <Form noValidate id='admin_company_form' placeholder={undefined}>
              <div className='row'>
                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <button type='submit' className='btn btn-lg btn-primary me-3 col-12'>
                    <span className='indicator-label'>Filtrele</span>
                  </button>
                </div>
              </div>

              <div className='row'>
                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <a
                    className='btn btn-lg btn-primary me-3 col-12'
                    onClick={() => {
                      resetForm({values: initFilterValues})
                    }}
                  >
                    <span className='indicator-label'>Temizle</span>
                  </a>
                </div>
              </div>

              <div className='row'>
                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                    <span className=''>Tarihi Aralığı</span>
                  </label>
                  <DateRangeSelector
                    setFieldValue={setFieldValue}
                    finishKey='finish'
                    startKey='start'
                  />
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export {SidebarAdminDashboardMenu}
