import {useSelector} from 'react-redux'
import {getAdvisorState} from '../../redux/advisorSlice'
import {getUserState} from '../../redux/userSlice'
import CompaniesTable from '../../components/admin/CompaniesTable'

function AssignedCompanyPage() {
  const userState = useSelector(getUserState)
  const advisorState = useSelector(getAdvisorState)

  return <CompaniesTable companies={advisorState.assignedCompanies ?? []} />
}

export default AssignedCompanyPage
