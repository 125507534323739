import {useEffect, useState} from 'react'
import FormikForm from '../../../components/form/FormikForm'
import {FieldArray, FormikValues} from 'formik'
import FormInput from '../../../components/form/FormInput'
import {KTSVG} from '../../../../_metronic/helpers'
import {useDispatch, useSelector} from 'react-redux'
import {BooleanApiData, CalendarData, CalendarDataListApiData} from '../../../api/data-contracts'
import {Api_Admin_GetCalendarData, Api_Admin_SetCalendar} from '../../../apiClient/apiClient'
import {getAdminState} from '../../../redux/adminSlice'
import {getRequestAsync, postRequestAsync} from '../../../redux/commonSlice'
import {FormStatus} from '../../../types/FormStatus'
import CustomDropdown from '../../../components/form/dropdown/CustomDropdown'
import {start} from 'repl'
import moment from 'moment'
import CustomerCalendar from '../../../components/customer/home/CustomerCalendar'

function HomePageCalendar() {
  const dispatch = useDispatch()
  const adminState = useSelector(getAdminState)

  const [calendars, setCalendars] = useState<CalendarData[] | null | undefined>([])

  let initData: {
    calendars: CalendarData[]
  } = {
    calendars: [],
  }

  function loadCalendar() {
    dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Admin_GetCalendarData,
      })
    )
      .unwrap()
      .then(async function (response: CalendarDataListApiData) {
        setCalendars(response.data ?? [])
        initData.calendars =
          response.data?.map((m) => {
            return {
              ...m,
              startTime: moment(m?.startTime ?? '').format('yyyy-MM-DD'),
              endTime: moment(m?.endTime ?? '').format('yyyy-MM-DD'),
              interval: m.interval ?? 0,
            }
          }) ?? []
      })
  }

  useEffect(() => {
    loadCalendar()
  }, [])

  const submit = async (
    values: {
      calendars: CalendarData[]
    },
    actions: FormikValues
  ) => {
    actions.setStatus()

    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_SetCalendar,
        body: values,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        if (response.data == true) {
          actions.setStatus(new FormStatus(true, 'Takvim Kaydedildi.'))
        } else {
          actions.setStatus(new FormStatus(false, response.serverMessage))
        }
        window.location.reload()
      })
  }

  return (
    <FormikForm initialValues={initData} onSubmit={submit}>
      {(props) => {
        return (
          <>
            <div className='w-100 mt-10'>
              <CustomerCalendar />
              <FieldArray
                name='calendars'
                render={(arrayHelpers) => (
                  <>
                    {props.values.calendars?.map((m, index) => {
                      return (
                        <div className='card shadow-lg mb-10' key={index}>
                          <div className='card-header border-0' style={{minHeight: 30}}>
                            <h3 className='card-title fw-bold text-dark'></h3>
                            <div className='card-toolbar'></div>
                          </div>
                          <div className='card-body'>
                            <div className='row'>
                              <div className='col-xl-11'>
                                <div className='row'>
                                  <div className='d-flex flex-column col-xl-6'>
                                    <FormInput
                                      fieldAttributes={{
                                        type: 'text',
                                        name: `calendars.${index}.title`,
                                        placeholder: 'BASLIK',
                                      }}
                                      istouched={true}
                                      errorMessage={undefined}
                                      placeHolderOnTop={true}
                                      checkValid={false}
                                    />
                                  </div>
                                  <div className='d-flex flex-column col-xl-2'>
                                    <CustomDropdown
                                      fieldAttributes={{
                                        name: `calendars.${index}.interval`,
                                        placeholder: 'ARALIK',
                                        onChange: (event: any) => {},
                                      }}
                                      noSelectionText='ARALIK_SECINIZ'
                                      options={[
                                        {
                                          label: 'Tekil',
                                          value: 0,
                                        },
                                        {
                                          label: 'Günlük',
                                          value: 1,
                                        },
                                        {
                                          label: 'Haftalık',
                                          value: 2,
                                        },
                                        {
                                          label: 'Aylık',
                                          value: 3,
                                        },
                                        {
                                          label: 'Yıllık',
                                          value: 4,
                                        },
                                      ]}
                                      noSelectionValue={0}
                                      placeHolderOnTop={true}
                                      setFieldValue={props.setFieldValue}
                                      valueConverter={parseInt}
                                    />
                                  </div>
                                  <div className='d-flex flex-column col-xl-2'>
                                    <FormInput
                                      fieldAttributes={{
                                        type: 'date',
                                        name: `calendars.${index}.startTime`,
                                        placeholder: 'BASLANGIC',
                                      }}
                                      istouched={true}
                                      errorMessage={undefined}
                                      placeHolderOnTop={true}
                                      checkValid={false}
                                    />
                                  </div>
                                  <div className='d-flex flex-column col-xl-2'>
                                    <FormInput
                                      fieldAttributes={{
                                        type: 'date',
                                        name: `calendars.${index}.endTime`,
                                        placeholder: 'BITIS',
                                      }}
                                      istouched={true}
                                      errorMessage={undefined}
                                      placeHolderOnTop={true}
                                      checkValid={false}
                                    />
                                  </div>
                                  <div className='col-xl-12'>
                                    <div className='d-flex flex-column mb-7 col-xl-12'>
                                      <FormInput
                                        fieldAttributes={{
                                          type: 'text',
                                          name: `calendars.${index}.description`,
                                          placeholder: 'ACIKLAMA',
                                          as: 'textarea',
                                          row: 5,
                                        }}
                                        istouched={true}
                                        errorMessage={undefined}
                                        placeHolderOnTop={true}
                                        checkValid={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-xl-1 d-flex align-items-end flex-column'>
                                <button
                                  type='button'
                                  className='btn btn-sm btn-icon btn-color-danger btn-active-light-danger'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/abstract/abs012.svg'
                                    className='svg-icon-muted svg-icon-2hx'
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    <div className='text-center'>
                      <button
                        type='button'
                        className='btn btn-sm btn-success me-3 mt-5'
                        onClick={() => arrayHelpers.push({})}
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr009.svg'
                          className='svg-icon-muted svg-icon-hx'
                        />
                        Takvim Kaydı Ekle
                      </button>
                    </div>
                  </>
                )}
              />
              <div className='row'>
                <div className='text-center'>
                  <button type='submit' className='btn btn-lg btn-primary me-3 mt-5'>
                    Kaydet
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      }}
    </FormikForm>
  )
}

export default HomePageCalendar
