/* eslint-disable react/jsx-no-target-blank */
import {KTSVG, initialListView} from '../../../../../_metronic/helpers'
import {useDispatch, useSelector} from 'react-redux'
import {getRequestAsync, postRequestAsync, setIsLoading} from '../../../../redux/commonSlice'
import {CSSProperties, useEffect} from 'react'
import {Formik, Field, FormikValues, Form} from 'formik'
import {
  getAdminState,
  setCompanies,
  setCompaniesStartup,
  setPotentialCustomers,
} from '../../../../redux/adminSlice'
import {
  GetAdminCompaniesRequest,
  AdminPanelCompanyDTOIEnumerableApiData,
  AdminPanelCompaniesStartupDTOApiData,
  CompanyTypeDTO,
  GetPotentialCustomersRequest,
  PotentialCustomerDTOIEnumerableApiData,
} from '../../../../api/data-contracts'
import {
  Api_Admin_GetCompanies,
  Api_Admin_GetCompaniesStartup,
  Api_Admin_GetPotentialCustomers,
} from '../../../../apiClient/apiClient'
import Select, {ClearIndicatorProps} from 'react-select'
import _ from 'lodash'
import moment from 'moment'
import DateRangeSelector from '../../../form/dropdown/DateRangeSelector'

const SidebarAdminPotentialCustomersMenu = () => {
  const dispatch = useDispatch()
  const adminState = useSelector(getAdminState)

  const submit = async (values: GetPotentialCustomersRequest) => {
    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_GetPotentialCustomers,
        body: values,
      })
    )
      .unwrap()
      .then(async function (response: PotentialCustomerDTOIEnumerableApiData) {
        dispatch<any>(setPotentialCustomers(response.data))
        //setFilteredCompanies(response.data ?? [])
        dispatch(setIsLoading(false))
      })
  }

  useEffect(() => {
    submit(initFilterValues)
  }, [])

  const initFilterValues: GetPotentialCustomersRequest = {
    textSearch: '',
    creationDateEnd: moment(new Date()).startOf('month').add(1, 'month').format('yyyy-MM-DD'),
    creationDateStart: moment(new Date()).startOf('month').format('yyyy-MM-DD'),
  }

  return (
    <>
      <Formik initialValues={initFilterValues} onSubmit={submit}>
        {({values, setFieldValue, status, resetForm, setValues}) => {
          return (
            <Form noValidate id='admin_company_form' placeholder={undefined}>
              <div className='row'>
                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <button type='submit' className='btn btn-lg btn-primary me-3 col-12'>
                    <span className='indicator-label'>Filtrele</span>
                  </button>
                </div>
              </div>

              <div className='row'>
                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <a
                    className='btn btn-lg btn-primary me-3 col-12'
                    onClick={() => {
                      resetForm({values: initFilterValues})
                    }}
                  >
                    <span className='indicator-label'>Temizle</span>
                  </a>
                </div>
              </div>

              <div className='row'>
                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                    <span className=''>Arama</span>
                  </label>
                  <Field
                    type='text'
                    name='textSearch'
                    className='form-control form-control-solid'
                    placeholder='Filtre'
                    value={values.textSearch}
                  />
                </div>

                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                    <span className=''>Kayıt Tarihi Aralığı</span>
                  </label>
                  <DateRangeSelector
                    setFieldValue={setFieldValue}
                    finishKey='creationDateEnd'
                    startKey='creationDateStart'
                  />
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export {SidebarAdminPotentialCustomersMenu}
