import {useDispatch, useSelector} from 'react-redux'
import {postRequestAsync, setIsLoading} from '../../../redux/commonSlice'
import {
  BooleanApiData,
  PaymentInvoiceDTO,
  PrintInvoiceRequest,
  StringApiData,
} from '../../../api/data-contracts'
import {
  Api_Admin_CancelInvoice,
  Api_Admin_GetInviocePreview,
  Api_Admin_PrintInvoice,
} from '../../../apiClient/apiClient'
import {getAdminState, getPaymentInvoicesAsync} from '../../../redux/adminSlice'
import {KTSVG} from '../../../../_metronic/helpers'
import _ from 'lodash'
import moment from 'moment'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'

function PaymentInvoiceTable() {
  const dispatch = useDispatch()
  const adminState = useSelector(getAdminState)

  function printInvoice(guid: string) {
    const body: PrintInvoiceRequest = {
      guidId: guid,
    }
    dispatch(setIsLoading(true))
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_PrintInvoice,
        body: body,
      })
    )
      .unwrap()
      .then(async function (response: BooleanApiData) {
        await dispatch<any>(getPaymentInvoicesAsync())
      })
  }

  const preview = async (guid: string) => {
    const body: PrintInvoiceRequest = {
      guidId: guid,
    }

    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_GetInviocePreview,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: StringApiData) => {
        var win = window.open(response.data ?? '', '_blank')
        win?.focus()
        dispatch(setIsLoading(false))
      })
  }

  const columns: ColumnDescription[] = [
    {
      dataField: 'paymentId',
      text: 'ID',
      sort: true,
      headerClasses: '',
      classes: '',
      width: 500,
      headerStyle: {width: '3%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-7'>{m.paymentId}</div>
        </>
      ),
    },
    {
      dataField: 'userName',
      text: 'Kullanıcı',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-7'>{m.userName}</div>
        </>
      ),
    },
    {
      dataField: 'isEinvoiceUser',
      text: 'Mükellef Mi',
      sort: true,
      headerClasses: '',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '7%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='d-flex justify-content-center flex-shrink-0'>
          {m.isEinvoiceUser === true ? (
            <KTSVG
              path='/media/icons/duotune/general/gen043.svg'
              className='svg-icon svg-icon-2hx'
            />
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      dataField: 'companyName',
      text: 'Firma',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark fw-bold d-block fs-7'>{m.companyName}</div>
      ),
    },
    {
      dataField: 'paymentState',
      text: 'Ödeme Durumu',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '15%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark fw-bold d-block fs-7'>{m.paymentState}</div>
      ),
    },
    {
      dataField: 'dueDate',
      text: 'Ödeme Tarihi',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark fw-bold d-block fs-7'>
          {' '}
          {moment(m.dueDate).format('DD.MM.yyyy HH:mm')}
        </div>
      ),
    },
    {
      dataField: 'invoiceAmount',
      text: 'Fatura',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '7%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark fw-bold d-block fs-7'>{Math.round(m.invoiceAmount ?? 0)} ₺</div>
      ),
    },
    {
      dataField: 'totalAmount',
      text: 'Toplam',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '7%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark fw-bold d-block fs-7'>{Math.round(m.totalAmount ?? 0)} ₺</div>
      ),
    },
    {
      dataField: 'isUbl',
      text: 'E-Fatura',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '7%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='d-flex justify-content-center flex-shrink-0'>
          {m.isUbl == true ? (
            <KTSVG
              path='/media/icons/duotune/general/gen043.svg'
              className='svg-icon svg-icon-2hx'
            />
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      dataField: 'missingInfo',
      text: 'Eksik Bilgiler	',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark  justify-content-center fw-bold d-block fs-7'>
          {m.missingInfo}
        </div>
      ),
    },
    {
      dataField: 'einvoiceStateDescription',
      text: 'E-Fatura Durum',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark  justify-content-center fw-bold d-block fs-7'>
          {m.einvoiceStateDescription}
        </div>
      ),
    },
    {
      dataField: 'printInfo',
      text: 'Basım',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '5%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark  justify-content-center fw-bold d-block fs-7'>
          {m.canTryPrint === 1 && (
            <button
              type='submit'
              onClick={function () {
                printInvoice(m.uuid ?? '')
              }}
              className='btn btn-sm btn-primary me-3'
            >
              <span className='indicator-label'>Bas</span>
            </button>
          )}
        </div>
      ),
    },
    {
      dataField: 'documentLink',
      text: 'PDF',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '5%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark  justify-content-center fw-bold d-block fs-7'>
          {(m.documentLink?.length ?? 0 > 0) && (
            <a href={m.documentLink ?? ''} target='_blank'>
              <KTSVG
                path='/media/icons/duotune/files/fil003.svg'
                className='svg-icon-muted svg-icon-2hx'
              />
            </a>
          )}
        </div>
      ),
    },
    {
      dataField: 'preview',
      text: 'Önizleme',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '5%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark  justify-content-center fw-bold d-block fs-7'>
          <a
            href={'#'}
            onClick={function () {
              preview(m.uuid ?? '')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/files/fil003.svg'
              className='svg-icon-muted svg-icon-2hx'
            />
          </a>
        </div>
      ),
    },
  ]

  return (
    <div className={`card h-100 mt-5`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Ödeme ve Faturalar</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='table-responsive'>
          {(adminState.invoices?.length ?? 0) > 0 && (
            <>
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  totalSize: adminState?.invoices?.length,
                  sizePerPage: 15,
                  paginationSize: 15,
                  paginationTotalRenderer: (from, to, size) => (
                    <span className='react-bootstrap-table-pagination-total ms-5'>
                      Toplam {size} faturadan {from} - {to} arası gösteriliyor
                    </span>
                  ),
                })}
              >
                {({paginationProps, paginationTableProps}) => (
                  <>
                    <div>
                      <SizePerPageDropdownStandalone {...paginationProps} />
                      <PaginationTotalStandalone {...paginationProps} />
                      <PaginationListStandalone {...paginationProps} />
                      <BootstrapTable
                        {...paginationTableProps}
                        keyField='paymentId'
                        data={_.orderBy(adminState.invoices, (o) => o.dueDate, 'desc') ?? []}
                        columns={columns}
                        classes='table table-hover table-row-dashed table-rounded mt-5'
                      />
                    </div>
                  </>
                )}
              </PaginationProvider>
            </>
          )}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default PaymentInvoiceTable
