/* eslint-disable jsx-a11y/anchor-is-valid */
import {useSelector} from 'react-redux'
import {getAdvisorState} from '../../../redux/advisorSlice'
import _ from 'lodash'
import AssignedCompanyTaxNumber from './AssignedCompanyTaxNumber'
import AssignedCompanySetSuspensionStatus from './AssignedCompanySetSuspensionStatus'

export function CompanyDetails() {
  const advisorState = useSelector(getAdvisorState)

  return (
    <>
      <div className='card-body'>
        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Şirket Adı</label>

          <div className='col-lg-9'>
            <span className='fw-bolder fs-6 text-dark'>
              {advisorState.assignedCompay?.company?.fullName}{' '}
              {advisorState.assignedCompay?.company?.isActive !== true ? '(Fesih)' : ''}
            </span>
          </div>
        </div>

        <div className='separator border-3 my-3 mx-1'></div>

        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Şirket Tipi</label>

          <div className='col-lg-9 fv-row'>
            <span className='fw-bolder fs-6'>{advisorState.assignedCompay?.company?.type}</span>
          </div>
        </div>

        <div className='separator border-3 my-3 mx-1'></div>

        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Adres</label>

          <div className='col-lg-9 d-flex align-items-center'>
            <span className='fw-bolder fs-6'>
              {advisorState.assignedCompay?.address?.fullAddressDescription}
            </span>
          </div>
        </div>

        <div className='separator border-3 my-3 mx-1'></div>

        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Ürünler</label>

          <div className='col-lg-9 d-flex align-items-center'>
            <span className='fw-bolder fs-6 me-2'>
              {_.uniq(advisorState.assignedCompay?.products?.map((m) => m.name))?.map((m) => {
                return <div key={m}>{m}</div>
              })}
            </span>
          </div>
        </div>

        <div className='separator border-3 my-3 mx-1'></div>

        {advisorState?.assignedCompay && <AssignedCompanyTaxNumber />}

        <div className='separator border-3 my-3 mx-1'></div>

        {(advisorState?.assignedCompay?.company?.suspensionStatusId ?? 0) > 0 && (
          <div className='row align-items-center'>
            <label className='col-lg-3 fw-bold text-muted'>Fesih İşlem Durumu</label>

            <div className='col-lg-3 d-flex align-items-center'>
              <span className='fw-bolder fs-6'>
                <div className='d-flex align-items-center'>
                  <div className='me-5'>
                    {advisorState?.assignedCompay?.company?.suspensionStatus}
                  </div>
                </div>
              </span>
            </div>
            <div className='col-lg-6 d-flex align-items-center'>
              <AssignedCompanySetSuspensionStatus />
            </div>

            <div className='separator border-3 my-3 mx-1'></div>
          </div>
        )}
      </div>
    </>
  )
}
