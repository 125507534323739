/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useSelector} from 'react-redux'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Card3} from '../../../../_metronic/partials/content/cards/Card3'
import {getAdvisorState} from '../../../redux/advisorSlice'
import {AssignedCompanyDTO} from '../../../api/data-contracts'

export function CompanyUsers() {
  const advisorState = useSelector(getAdvisorState)
  const color = 'danger'
  return (
    <>
      <div className='row g-6 g-xl-9'>
        {advisorState.assignedCompay?.users?.map((m) => {
          return (
            <div className='col-md-6 col-xxl-4' key={m.guid}>
              <div className='card'>
                <div className='card-body d-flex flex-center flex-column p-9'>
                  <div className='mb-5'>
                    <div className='symbol symbol-75px symbol-circle'>
                      {color && (
                        <span
                          className={`symbol-label bg-light-${color} text-${color} fs-5 fw-bolder`}
                        >
                          {m.firstName?.charAt(0)}
                        </span>
                      )}
                    </div>
                  </div>

                  <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                    {m.firstName + ' ' + m.lastName}
                  </a>

                  <div className='fw-bold text-gray-400 mb-6'></div>

                  <div className='fs-6 fw-bolder text-gray-700'>{m?.phoneNumber}</div>
                  <div className='fs-6 fw-bolder text-gray-700'>{m?.mailAddress}</div>
                  <div className='fs-6 fw-bolder text-gray-700'>Kimlik No: {m?.idNumber}</div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
