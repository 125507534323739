import React from 'react'

function CompanyStateProgressBar(props: {percentage: number}) {
  function getBackgroundColor() {
    if (props.percentage <= 33) {
      return 'bg-danger'
    } else if (props.percentage <= 80) {
      return 'bg-warning'
    } else if (props.percentage >= 80 && props.percentage < 100) {
      return 'bg-primary'
    } else if (props.percentage == 100) {
      return 'bg-success'
    } else {
      return 'bg-danger'
    }
  }

  return (
    <div
      className={'progress-bar ' + getBackgroundColor()}
      role='progressbar'
      style={{width: `${props.percentage}%`}}
    ></div>
  )
}

export default CompanyStateProgressBar
