import {useDispatch, useSelector} from 'react-redux'
import {getAdvisorState, loadAssingedCompanyAsync} from '../../../redux/advisorSlice'
import {BooleanApiData, SetCompanySuspensionStatusRequest} from '../../../api/data-contracts'
import {postRequestAsync, setIsLoading} from '../../../redux/commonSlice'
import {Api_ServiceProvider_SetCompanySuspensionStatus} from '../../../apiClient/apiClient'
import _ from 'lodash'
import {CompanySuspensionStatusEnum} from '../../../apiClient/enums'
import AreYouSurePopup from '../../../components/common/popup/AreYouSurePopup'
function AssignedCompanySetSuspensionStatus() {
  const dispatch = useDispatch()
  const advisorState = useSelector(getAdvisorState)

  async function setStatus(statusId: number) {
    dispatch(setIsLoading(true))

    const values: SetCompanySuspensionStatusRequest = {
      suspensionStatusId: statusId,
      companyId: advisorState.assignedCompay?.company?.guid ?? '',
    }
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_ServiceProvider_SetCompanySuspensionStatus,
        body: values,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        await dispatch<any>(
          loadAssingedCompanyAsync({companyId: advisorState.assignedCompay?.company?.guid ?? ''})
        )
      })
  }

  return (
    <div className='d-flex'>
      {advisorState?.assignedCompay?.company?.suspensionStatusId ==
        CompanySuspensionStatusEnum.Mali_Müşavir_Değiştirmek_İstiyor && (
        <>
          <AreYouSurePopup
            modalOpener={
              <button type='submit' className='btn btn-lg btn-danger me-3'>
                <span className='indicator-label'>Mali Müşavir Değişim İptal</span>
              </button>
            }
            message='FESIH_DURUMUNU_DEGISTIRMEK_ISTEDIGINIZDEN_EMIN_MISINIZ'
            onClick={() => setStatus(0)}
          />
          <AreYouSurePopup
            modalOpener={
              <button type='submit' className='btn btn-lg btn-warning me-3'>
                <span className='indicator-label'>Mali Müşavir Değişim Başlat</span>
              </button>
            }
            message='FESIH_DURUMUNU_DEGISTIRMEK_ISTEDIGINIZDEN_EMIN_MISINIZ'
            onClick={() => setStatus(CompanySuspensionStatusEnum.Mali_Müşavir_Değişim_Başladı)}
          />
        </>
      )}

      {advisorState?.assignedCompay?.company?.suspensionStatusId ==
        CompanySuspensionStatusEnum.Mali_Müşavir_Değişim_Başladı && (
        <>
          <AreYouSurePopup
            modalOpener={
              <button type='submit' className='btn btn-lg btn-danger me-3'>
                <span className='indicator-label'>Mali Müşavir Değişim İptal</span>
              </button>
            }
            message='FESIH_DURUMUNU_DEGISTIRMEK_ISTEDIGINIZDEN_EMIN_MISINIZ'
            onClick={() => setStatus(0)}
          />
          <AreYouSurePopup
            modalOpener={
              <button type='submit' className='btn btn-lg btn-warning me-3'>
                <span className='indicator-label'>Mali Müşavir Değişim Tamamlandı</span>
              </button>
            }
            message='FESIH_DURUMUNU_DEGISTIRMEK_ISTEDIGINIZDEN_EMIN_MISINIZ'
            onClick={() => setStatus(CompanySuspensionStatusEnum.Mali_Müşavir_Değiştirdi)}
          />
        </>
      )}

      {advisorState?.assignedCompay?.company?.suspensionStatusId ==
        CompanySuspensionStatusEnum.Şirketi_Kapatmak_İstiyor && (
        <>
          <AreYouSurePopup
            modalOpener={
              <button type='submit' className='btn btn-lg btn-danger me-3'>
                <span className='indicator-label'>Tasfiye İptal Et</span>
              </button>
            }
            message='FESIH_DURUMUNU_DEGISTIRMEK_ISTEDIGINIZDEN_EMIN_MISINIZ'
            onClick={() => setStatus(0)}
          />
          <AreYouSurePopup
            modalOpener={
              <button type='submit' className='btn btn-lg btn-warning me-3'>
                <span className='indicator-label'>Tasfiye Başladı</span>
              </button>
            }
            message='FESIH_DURUMUNU_DEGISTIRMEK_ISTEDIGINIZDEN_EMIN_MISINIZ'
            onClick={() => setStatus(CompanySuspensionStatusEnum.Tasfiye_Başladı)}
          />
        </>
      )}

      {advisorState?.assignedCompay?.company?.suspensionStatusId ==
        CompanySuspensionStatusEnum.Tasfiye_Başladı && (
        <>
          <AreYouSurePopup
            modalOpener={
              <button type='submit' className='btn btn-lg btn-danger me-3'>
                <span className='indicator-label'>Tasfiye İptal Et</span>
              </button>
            }
            message='FESIH_DURUMUNU_DEGISTIRMEK_ISTEDIGINIZDEN_EMIN_MISINIZ'
            onClick={() => setStatus(0)}
          />
          <AreYouSurePopup
            modalOpener={
              <button type='submit' className='btn btn-lg btn-warning me-3'>
                <span className='indicator-label'>Tasfiye Tamamlandı</span>
              </button>
            }
            message='FESIH_DURUMUNU_DEGISTIRMEK_ISTEDIGINIZDEN_EMIN_MISINIZ'
            onClick={() =>
              setStatus(CompanySuspensionStatusEnum.Şirket_Kapandı___Tasfiye_Sonuçlandı)
            }
          />
        </>
      )}
    </div>
  )
}

export default AssignedCompanySetSuspensionStatus
