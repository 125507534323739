import React from 'react'

function ReportButton(props: {loader: () => void; name: string}) {
  return (
    <div className='row'>
      <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
        <a
          className='btn btn-lg btn-primary me-3 col-12'
          onClick={() => {
            props.loader()
          }}
        >
          <span className='indicator-label'>{props.name}</span>
        </a>
      </div>
    </div>
  )
}

export default ReportButton
