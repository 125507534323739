/* eslint-disable react/jsx-no-target-blank */
import {KTSVG} from '../../../../../_metronic/helpers'
import {useDispatch, useSelector} from 'react-redux'
import {getRequestAsync, postRequestAsync, setIsLoading} from '../../../../redux/commonSlice'
import {CSSProperties, useEffect} from 'react'
import {Formik, Field, FormikValues, Form} from 'formik'
import {getAdminState, setCompanies, setCompaniesStartup} from '../../../../redux/adminSlice'
import {
  GetAdminCompaniesRequest,
  AdminPanelCompanyDTOIEnumerableApiData,
  AdminPanelCompaniesStartupDTOApiData,
  CompanyTypeDTO,
} from '../../../../api/data-contracts'
import {
  Api_Admin_GetCompanies,
  Api_Admin_GetCompaniesStartup,
} from '../../../../apiClient/apiClient'
import Select, {ClearIndicatorProps} from 'react-select'
import _ from 'lodash'
import DateRangeSelector from '../../../form/dropdown/DateRangeSelector'
import moment from 'moment'
import {DropdownOption, defterdar_log} from '../../../../utils/utilities'

const SidebarAdminCompanyMenu = () => {
  const dispatch = useDispatch()
  const adminState = useSelector(getAdminState)

  const submit = async (values: GetAdminCompaniesRequest) => {
    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_GetCompanies,
        body: values,
      })
    )
      .unwrap()
      .then(async function (response: AdminPanelCompanyDTOIEnumerableApiData) {
        dispatch<any>(setCompanies(response.data))
        //setFilteredCompanies(response.data ?? [])
        dispatch(setIsLoading(false))
      })
  }

  async function loadStartup() {
    dispatch(setIsLoading(true))
    await dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Admin_GetCompaniesStartup,
      })
    )
      .unwrap()
      .then(async function (response: AdminPanelCompaniesStartupDTOApiData) {
        dispatch<any>(setCompaniesStartup(response.data))
        //setFilteredCompanies(response.data ?? [])
        dispatch(setIsLoading(false))
      })

    await submit(initFilterValues)
  }

  useEffect(() => {
    loadStartup()
  }, [])

  const initFilterValues: GetAdminCompaniesRequest = {
    textSearch: '',
    creationDateEnd: moment(new Date()).startOf('month').add(1, 'month').format('yyyy-MM-DD'),
    creationDateStart: moment(new Date()).startOf('month').format('yyyy-MM-DD'),
    activePassive: -1,
    assignedFinancialAdvisorUserId: undefined,
    deptAmount: undefined,
    boughtProducts: [],
    companyTypes: [],
    states: [],
  }

  const activePassiveOptions: DropdownOption[] = [
    {
      label: 'Hepsi',
      value: '-1',
    },
    {
      label: 'Pasif',
      value: '0',
    },
    {
      label: 'Aktif',
      value: '1',
    },
  ]

  return (
    <>
      <Formik initialValues={initFilterValues} onSubmit={submit}>
        {({values, setFieldValue, status, resetForm}) => {
          return (
            <Form noValidate id='admin_company_form' placeholder={undefined}>
              {/* <div className='row text-white'>{JSON.stringify(values)}</div> */}

              <div className='row'>
                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <button type='submit' className='btn btn-lg btn-primary me-3 col-12'>
                    <span className='indicator-label'>Filtrele</span>
                  </button>
                </div>
              </div>

              <div className='row'>
                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <a
                    className='btn btn-lg btn-primary me-3 col-12'
                    onClick={() => {
                      resetForm({values: initFilterValues})
                    }}
                  >
                    <span className='indicator-label'>Temizle</span>
                  </a>
                </div>
              </div>

              <div className='row'>
                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                    <span className=''>Arama</span>
                  </label>
                  <Field
                    type='text'
                    name='textSearch'
                    className='form-control form-control-solid fs-8'
                    placeholder='Filtre'
                  />
                </div>

                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                    <span className=''>Kayıt Tarihi Aralığı</span>
                  </label>
                  <DateRangeSelector
                    setFieldValue={setFieldValue}
                    finishKey='creationDateEnd'
                    startKey='creationDateStart'
                  />
                </div>

                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                  <span className=''>Aktif - Pasif</span>
                </label>
                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <Select
                    options={activePassiveOptions}
                    closeMenuOnSelect={true}
                    name='activePassive'
                    onChange={(value: any) => {
                      defterdar_log(value)
                      setFieldValue('activePassive', parseInt((value as DropdownOption).value))
                    }}
                    classNames={{
                      control: (state) => 'form-select form-select-solid fs-8',
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        paddingTop: '0',
                        paddingBottom: '0',
                      }),
                      dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        display: 'none',
                      }),
                    }}
                  />
                </div>

                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                  <span className=''>Mali Müşavir</span>
                </label>
                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <Select
                    options={
                      adminState.companiesStartup?.financialAdvisors?.map((m) => {
                        const d: DropdownOption = {
                          label:
                            (m.description ?? '') +
                            ' (' +
                            adminState?.companies?.filter(
                              (f) => f.assignedFinancialAdvisorId == m.id
                            ).length +
                            ')',
                          value: m.id?.toString() ?? '0',
                        }
                        return d
                      }) ?? []
                    }
                    closeMenuOnSelect={true}
                    name='assignedFinancialAdvisorUserId'
                    onChange={(value: any) => {
                      setFieldValue(
                        'assignedFinancialAdvisorUserId',
                        parseInt((value as DropdownOption).value)
                      )
                    }}
                    classNames={{
                      control: (state) => 'form-select form-select-solid fs-8',
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        paddingTop: '0',
                        paddingBottom: '0',
                      }),
                      dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        display: 'none',
                      }),
                    }}
                  />
                </div>

                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                  <span className=''>Şirket Tipi</span>
                </label>
                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <Select
                    options={
                      adminState.companiesStartup?.companyTypes?.map((m) => {
                        const d: DropdownOption = {
                          label:
                            (m.description ?? '') +
                            ' (' +
                            adminState?.companies?.filter((f) => f.type == m.description).length +
                            ')',
                          value: m.companyTypeId?.toString() ?? '0',
                        }
                        return d
                      }) ?? []
                    }
                    isMulti
                    closeMenuOnSelect={false}
                    name='companyTypes'
                    onChange={(value) => {
                      setFieldValue(
                        'companyTypes',
                        (value as DropdownOption[]).map((m) => Number(m.value))
                      )
                    }}
                    classNames={{
                      control: (state) => 'form-select form-select-solid fs-8',
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        paddingTop: '0',
                        paddingBottom: '0',
                      }),
                      dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        display: 'none',
                      }),
                    }}
                  />
                </div>

                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                  <span className=''>Durum</span>
                </label>
                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <Select
                    // loadOptions={loadOptions}
                    options={
                      _.orderBy(adminState.companiesStartup?.states, (o) => o.progressIndex)?.map(
                        (m) => {
                          const d: DropdownOption = {
                            label:
                              (m.progressIndex + ' - ' + m.description ?? '') +
                              ' (' +
                              adminState.companies?.filter(
                                (f) => f.stepDescription == m.description
                              ).length +
                              ')',
                            value: m.applicationStepId?.toString() ?? '0',
                          }
                          return d
                        }
                      ) ?? []
                    }
                    isMulti
                    closeMenuOnSelect={false}
                    name='states'
                    onChange={(value) => {
                      setFieldValue(
                        'states',
                        (value as DropdownOption[]).map((m) => Number(m.value))
                      )
                    }}
                    classNames={{
                      control: (state) => 'form-select form-select-solid fs-8',
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        paddingTop: '0',
                        paddingBottom: '0',
                      }),
                      dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        display: 'none',
                      }),
                    }}
                  />
                </div>

                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                  <span className=''>Fesih Durum</span>
                </label>
                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <Select
                    options={
                      adminState.companiesStartup?.suspensionStates?.map((m) => {
                        const d: DropdownOption = {
                          label:
                            (m.description ?? '') +
                            ' (' +
                            adminState?.companies?.filter((f) => f.suspensionStatusId == m.id)
                              .length +
                            ')',
                          value: m.id?.toString() ?? '0',
                        }
                        return d
                      }) ?? []
                    }
                    isMulti
                    closeMenuOnSelect={false}
                    name='suspensionStates'
                    onChange={(value) => {
                      setFieldValue(
                        'suspensionStates',
                        (value as DropdownOption[]).map((m) => Number(m.value))
                      )
                    }}
                    classNames={{
                      control: (state) => 'form-select form-select-solid fs-8',
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        paddingTop: '0',
                        paddingBottom: '0',
                      }),
                      dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        display: 'none',
                      }),
                    }}
                  />
                </div>

                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                    <span className=''>Borç</span>
                  </label>
                  <Field
                    type='number'
                    name='deptAmount'
                    className='form-control form-control-solid fs-8'
                    placeholder=''
                    onChange={(event: any) => {
                      setFieldValue('deptAmount', Number(event.target.value))
                    }}
                  />
                </div>

                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-white'>
                  <span className=''>Ürünler</span>
                </label>
                <div className='d-flex flex-column mb-3 fv-row col-xl-12'>
                  <Select
                    // loadOptions={loadOptions}
                    options={
                      adminState.companiesStartup?.products?.map((m) => {
                        const d: DropdownOption = {
                          label: (m.companyType ? m.companyType + '-' : '') + m.title ?? '',
                          value: m.id?.toString() ?? '0',
                        }
                        return d
                      }) ?? []
                    }
                    isMulti
                    closeMenuOnSelect={false}
                    name='boughtProducts'
                    onChange={(value) => {
                      setFieldValue(
                        'boughtProducts',
                        (value as DropdownOption[]).map((m) => Number(m.value))
                      )
                    }}
                    classNames={{
                      control: (state) => 'form-select form-select-solid fs-8',
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        paddingTop: '0',
                        paddingBottom: '0',
                      }),
                      dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        display: 'none',
                      }),
                    }}
                  />
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export {SidebarAdminCompanyMenu}
