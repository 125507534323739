import {Formik, Form, Field} from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  GetPaymentInvoicesRequest,
  AdminPanelInvoicesStartupDTOApiData,
  BooleanApiData,
} from '../../../api/data-contracts'
import {
  Api_Admin_GetInvoicesStartup,
  Api_Admin_CheckProcessingInvoices,
} from '../../../apiClient/apiClient'
import DateRangeSelector from '../../../components/form/dropdown/DateRangeSelector'
import {
  getAdminState,
  setGetPaymentInvoicesRequest,
  getPaymentInvoicesAsync,
  setInvoicesStartup,
} from '../../../redux/adminSlice'
import {setIsLoading, getRequestAsync} from '../../../redux/commonSlice'
import {DropdownOption} from '../../../utils/utilities'
import Select from 'react-select'
import DateRangeSelectorHorizontal from '../../../components/form/dropdown/DateRangeSelectorHorizontal'

function PaymentInvoiceFilter() {
  const dispatch = useDispatch()
  const adminState = useSelector(getAdminState)

  const submit = async (values: GetPaymentInvoicesRequest) => {
    dispatch(setGetPaymentInvoicesRequest(values))
    await dispatch<any>(getPaymentInvoicesAsync())
  }

  async function loadStartup() {
    dispatch(setIsLoading(true))
    await dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Admin_GetInvoicesStartup,
      })
    )
      .unwrap()
      .then(async function (response: AdminPanelInvoicesStartupDTOApiData) {
        dispatch<any>(setInvoicesStartup(response.data))
        //setFilteredCompanies(response.data ?? [])
        dispatch(setIsLoading(false))
      })

    await submit(initFilterValues)
  }

  useEffect(() => {
    loadStartup()
  }, [])

  const initFilterValues: GetPaymentInvoicesRequest = {
    startTime: moment(new Date()).startOf('month').format('yyyy-MM-DD'),
    endTime: moment(new Date()).startOf('month').add(1, 'month').format('yyyy-MM-DD'),
    invoiceStates: [],
    isPrinted: null,
    paymentStates: [],
    textSearch: '',
  }

  return (
    <div className={`card h-100 mt-3`}>
      <div className='card-body py-3'>
        <Formik initialValues={initFilterValues} onSubmit={submit}>
          {({values, setFieldValue, status, resetForm, setValues}) => {
            return (
              <Form noValidate id='admin_company_form' placeholder={undefined}>
                <div className='row mt-4'>
                  <div className='d-flex flex-column mb-3 fv-row col-xl-6'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className=''>Tarih Seçimi</span>
                    </label>
                    <div className='mx-2'>
                      <DateRangeSelectorHorizontal
                        setFieldValue={setFieldValue}
                        finishKey='endTime'
                        startKey='startTime'
                      />
                    </div>
                  </div>

                  <div className='d-flex flex-column mb-3 fv-row col-xl-3'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className=''>Fatura Durumu</span>
                    </label>
                    <Select
                      // loadOptions={loadOptions}
                      options={
                        _.orderBy(
                          adminState.invoicesStartup?.invoiceStates,
                          (o) => o.description
                        )?.map((m) => {
                          const d: DropdownOption = {
                            label:
                              (m.description ?? '') +
                              ' (' +
                              adminState.invoices?.filter(
                                (f) => f.foribaWebServiceResultCode == m.id
                              ).length +
                              ')',
                            value: m.id?.toString() ?? '0',
                          }
                          return d
                        }) ?? []
                      }
                      isMulti
                      closeMenuOnSelect={false}
                      name='invoiceStates'
                      onChange={(value) => {
                        setFieldValue(
                          'invoiceStates',
                          (value as DropdownOption[]).map((m) => Number(m.value))
                        )
                      }}
                      classNames={{
                        control: (state) => 'form-select form-select-solid fs-6 py-1',
                      }}
                      styles={{
                        dropdownIndicator: (baseStyles, state) => ({
                          ...baseStyles,
                          display: 'none',
                        }),
                      }}
                    />
                  </div>

                  <div className='d-flex flex-column mb-3 fv-row col-xl-3'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className=''>Arama</span>
                    </label>
                    <Field
                      type='text'
                      name='textSearch'
                      className='form-control form-control-solid fs-6'
                      placeholder='Filtre'
                    />
                  </div>

                  <div className='d-flex flex-column mb-3 fv-row offset-6 col-xl-3'>
                    <a
                      className='btn btn-lg btn-primary col-12'
                      onClick={() => {
                        resetForm({values: initFilterValues})
                      }}
                    >
                      <span className='indicator-label'>Temizle</span>
                    </a>
                  </div>

                  <div className='d-flex flex-column mb-3 fv-row col-xl-3'>
                    <button type='submit' className='btn btn-lg btn-primary col-12'>
                      <span className='indicator-label'>Filtrele</span>
                    </button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default PaymentInvoiceFilter
