import {useDispatch, useSelector} from 'react-redux'
import {
  AdminPanelCompanyDTO,
  BooleanApiData,
  PotentialCustomerDTO,
  UpdateUserActiveStatusRequest,
  UpdateUserStateRequest,
} from '../../../api/data-contracts'
import {getAdminState} from '../../../redux/adminSlice'
import {KTSVG} from '../../../../_metronic/helpers'
import CompanyStateProgressBar from '../Components/CompanyStateProgressBar'
import AdminCompanyDetailPopup from '../Companies/AdminCompanyDetailPopup'
import _ from 'lodash'
import moment from 'moment'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AreYouSurePopup from '../../../components/common/popup/AreYouSurePopup'
import {Api_Admin_ChangeUserActive, Api_Admin_ChangeUserState} from '../../../apiClient/apiClient'
import {setIsLoading, postRequestAsync, closeAreYouSureModal} from '../../../redux/commonSlice'
import clsx from 'clsx'
import {stat} from 'fs'

export interface AdminCompaniesFilter {
  textFilter: string
  companyType: number
}

function AdminPotentialsPage() {
  const dispatch = useDispatch()
  const adminState = useSelector(getAdminState)

  const changeUserActive = async (userId: string, state: boolean) => {
    dispatch(setIsLoading(true))
    const body: UpdateUserStateRequest = {
      userId: userId,
      isActive: state,
    }
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_ChangeUserState,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        dispatch(closeAreYouSureModal())
        dispatch(setIsLoading(false))
        window.location.reload()
      })
  }

  const columns: ColumnDescription[] = [
    {
      dataField: 'firstName',
      text: 'Kişi',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '15%'},
      formatter: (cell: any, m: PotentialCustomerDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>
            {m.firstName} {m.lastName}
          </div>
        </>
      ),
    },
    {
      dataField: 'mailAddress',
      text: 'E-mail',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '15%'},
      formatter: (cell: any, m: PotentialCustomerDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>{m.mailAddress}</div>
        </>
      ),
    },
    {
      dataField: 'phoneNumber',
      text: 'Telefon',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '15%'},
      formatter: (cell: any, m: PotentialCustomerDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>{m.phoneNumber}</div>
        </>
      ),
    },
    {
      dataField: 'createdAt',
      text: 'Kayıt Zamanı',
      sort: true,
      headerStyle: {width: '15%'},
      formatter: (cell: any, m: PotentialCustomerDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>
            {moment(m.createdAt).format('DD.MM.yyyy HH:mm')}
          </div>
        </>
      ),
    },
    {
      dataField: 'createdAt',
      text: 'İşlem',
      sort: true,
      headerStyle: {width: '15%'},
      formatter: (cell: any, m: PotentialCustomerDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className=''>
            <AreYouSurePopup
              modalOpener={
                <a className='btn btn-lg btn-warning me-3'>
                  <span className='indicator-label'>
                    {m.isActive ? 'Kullanıcı Pasife Al' : 'Kullanıcı Aktif Et'}
                  </span>
                </a>
              }
              message={'EMIN_MISINIZ'}
              onClick={() => changeUserActive(m.guid ?? '', m.isActive ? false : true)}
            />
          </div>
        </>
      ),
    },
  ]

  const rowClasses = (row: PotentialCustomerDTO, rowIndex: number): string => {
    return clsx({
      'bg-danger bg-opacity-15': !row.isActive,
    })
  }

  return (
    <>
      <div className={`card card-stretch`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5 d-flex justify-content-center'>
          <h3 className='card-title flex-column'>
            <div className='fw-bolder fs-1 text-center py-5'>Talepler</div>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}

        <div className='card-body py-3'>
          <div className='table-responsive'>
            {(adminState.potentialCustomers?.length ?? 0) > 0 && (
              <>
                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    totalSize: adminState?.potentialCustomers?.length,
                    sizePerPage: 15,
                    paginationSize: 15,
                    paginationTotalRenderer: (from, to, size) => (
                      <span className='react-bootstrap-table-pagination-total ms-5'>
                        Toplam {size} talepten {from} - {to} arası gösteriliyor
                      </span>
                    ),
                  })}
                >
                  {({paginationProps, paginationTableProps}) => (
                    <>
                      <div>
                        <SizePerPageDropdownStandalone {...paginationProps} />
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                        <BootstrapTable
                          {...paginationTableProps}
                          keyField='index'
                          rowClasses={rowClasses}
                          data={
                            _.orderBy(adminState.potentialCustomers, (o) => o.createdAt, 'desc') ??
                            []
                          }
                          columns={columns}
                          classes='table table-hover table-row-dashed table-rounded mt-5'
                        />
                      </div>
                    </>
                  )}
                </PaginationProvider>
              </>
            )}
          </div>

          {/* begin::Table container */}

          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default AdminPotentialsPage
