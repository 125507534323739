import {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'
import {AssignedCompanyHeader} from './AssignedCompanyHeader'
import {CompanyDetails} from './CompanyDetails'
import {CompanyUsers} from './CompanyUsers'
import {GetAssignedCompanyRequest} from '../../../api/data-contracts'
import {useDispatch, useSelector} from 'react-redux'
import DocumentsPage from '../../document/DocumentsPage'
import PaymentsTable from '../../../components/customer/finance/PaymentsTable'
import clsx from 'clsx'
import {ApplicationStepEnum} from '../../../apiClient/enums'
import {getAdvisorState, loadAssingedCompanyAsync} from '../../../redux/advisorSlice'

function AssignedCompanyPopup(props: {companyGuidId: string}) {
  const [modalShow, setModalShow] = useState(false)
  const handleModalClose = () => setModalShow(false)

  const dispatch = useDispatch()

  const advisorState = useSelector(getAdvisorState)

  function loadCompany() {
    const body: GetAssignedCompanyRequest = {
      companyId: props.companyGuidId,
    }

    dispatch<any>(loadAssingedCompanyAsync({companyId: props.companyGuidId ?? ''}))
  }

  useEffect(() => {
    if (modalShow) {
      loadCompany()
    }
  }, [modalShow])

  return (
    <>
      <a
        href='#'
        onClick={() => {
          setModalShow(true)
        }}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
      >
        <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
      </a>

      <Modal size='xl' show={modalShow} onHide={handleModalClose} centered id='kt_modal_success'>
        <Modal.Header closeButton>
          <div className='d-flex flex-column justify-content-center'>
            <div className='fs-1 text-center fw-bold'>
              {advisorState?.assignedCompay?.company?.fullName}
            </div>
            <div>
              <AssignedCompanyHeader />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='card card-border w-100 d-flex flex-row-fluid flex-center h-100'>
            <div className='card-header card-header-stretch'>
              <div className='card-toolbar'>
                <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-4 fw-bold border-0'>
                  <li className='nav-item'>
                    <a
                      className={'nav-link active'}
                      data-bs-toggle='tab'
                      href={'#kt_tab_panel_general_info'}
                    >
                      Genel Bilgiler
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className={'nav-link'} data-bs-toggle='tab' href={'#kt_tab_panel_users'}>
                      Kişiler
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className={clsx('nav-link', {
                        'opacity-50 disabled':
                          advisorState.assignedCompay?.company?.stepId ==
                          ApplicationStepEnum.Turkey_WizardFinished,
                      })}
                      data-bs-toggle='tab'
                      href={'#kt_tab_panel_document'}
                    >
                      Dökümanlar
                      {advisorState.assignedCompay?.company?.stepId ==
                        ApplicationStepEnum.Turkey_WizardFinished && (
                        <i className='fa-solid fa-lock px-2'></i>
                      )}
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className={'nav-link'} data-bs-toggle='tab' href={'#kt_tab_panel_payments'}>
                      Ödemeler
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='card-body pt-0 fs-6 w-100 pt-1'>
              <div className='mb-3 mt-2'></div>

              <div className='tab-content' id='myTabContent'>
                <div
                  className={'tab-pane fade active show'}
                  id={'kt_tab_panel_general_info'}
                  role='tabpanel'
                >
                  <CompanyDetails />
                </div>
                <div className={'tab-pane fade'} id={'kt_tab_panel_users'} role='tabpanel'>
                  <CompanyUsers />
                </div>
                <div className={'tab-pane fade'} id={'kt_tab_panel_document'} role='tabpanel'>
                  {advisorState.assignedCompay && (
                    <DocumentsPage companyId={advisorState.assignedCompay?.company?.guid ?? ''} />
                  )}
                </div>
                <div className={'tab-pane fade'} id={'kt_tab_panel_payments'} role='tabpanel'>
                  <div className='w-100'>
                    <PaymentsTable
                      canPay={false}
                      canCancel={false}
                      payments={advisorState.assignedCompay?.payments ?? []}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AssignedCompanyPopup
