import React, {useEffect, useState} from 'react'
import HomeBanner from '../../customer/home/HomeBanner'
import FormikForm from '../../../components/form/FormikForm'
import {FieldArray, FormikValues} from 'formik'
import FormInput from '../../../components/form/FormInput'
import TranslatorText from '../../../components/common/translate/TranslatorText'
import Translator from '../../../components/common/translate/Translator'
import {convertBase64, defterdar_log, isUrl} from '../../../utils/utilities'
import {BannerData} from '../../customer/home/BannerData'
import NormalTitle from '../../../components/common/title/NormalTitle'
import {KTSVG} from '../../../../_metronic/helpers'
import {useDispatch, useSelector} from 'react-redux'
import {
  AdminProductPageDataApiData,
  Banner,
  BannerListApiData,
  BooleanApiData,
  SetBannersRequest,
} from '../../../api/data-contracts'
import {
  Api_Admin_GetBanners,
  Api_Admin_GetProductsPage,
  Api_Admin_SetBanners,
} from '../../../apiClient/apiClient'
import {getAdminState, setProductsPageData} from '../../../redux/adminSlice'
import {getRequestAsync, postRequestAsync} from '../../../redux/commonSlice'
import {FormStatus} from '../../../types/FormStatus'

function HomePageBanners() {
  const dispatch = useDispatch()
  const adminState = useSelector(getAdminState)

  const [banners, setBanners] = useState<Banner[] | null | undefined>([])

  let initData: {
    banners: BannerData[]
  } = {
    banners: [],
  }

  function loadBanners() {
    dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Admin_GetBanners,
      })
    )
      .unwrap()
      .then(async function (response: BannerListApiData) {
        setBanners(response.data)
        initData.banners =
          response.data?.map((m) => {
            return {
              Description: m.description ?? '',
              File: undefined,
              Title: m.title ?? '',
              Image: m.url ?? '',
            }
          }) ?? []
      })
  }

  useEffect(() => {
    loadBanners()
  }, [])

  const submit = async (
    values: {
      banners: BannerData[]
    },
    actions: FormikValues
  ) => {
    actions.setStatus()

    let bannerRequest: SetBannersRequest = {
      banners: await Promise.all(
        values.banners.map(async (m) => {
          return {
            description: m.Description,
            extension: m.File?.type,
            fileName: m.File?.name,
            imageData: m.File ? ((await convertBase64(m.File)) as string) : null,
            title: m.Title,
            url: m.Image,
          }
        })
      ),
    }

    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_SetBanners,
        body: bannerRequest,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        if (response.data == true) {
          actions.setStatus(new FormStatus(true, 'Bannerlar Kaydedildi.'))
        } else {
          actions.setStatus(new FormStatus(false, response.serverMessage))
        }
        window.location.reload()
      })
  }

  return (
    <FormikForm initialValues={initData} onSubmit={submit}>
      {(props) => {
        return (
          <>
            <NormalTitle text='ONIZLEME' />
            <HomeBanner banners={props.values.banners} />
            <div className='w-100 mt-10'>
              <NormalTitle text='BANNERLAR' />
              <FieldArray
                name='banners'
                render={(arrayHelpers) => (
                  <>
                    {props.values.banners.map((m, index) => {
                      return (
                        <div className='card shadow-lg mb-10' key={index}>
                          <div className='card-header border-0' style={{minHeight: 30}}>
                            <h3 className='card-title fw-bold text-dark'></h3>
                            <div className='card-toolbar'></div>
                          </div>
                          <div className='card-body'>
                            <div className='row' key={index}>
                              <div className='col-xl-6'>
                                <div className='row'>
                                  <div className='d-flex flex-column col-xl-12'>
                                    <FormInput
                                      fieldAttributes={{
                                        type: 'text',
                                        name: `banners.${index}.Title`,
                                        placeholder: 'BASLIK',
                                      }}
                                      istouched={true}
                                      errorMessage={undefined}
                                      placeHolderOnTop={true}
                                      checkValid={false}
                                    />
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='d-flex flex-column mb-7 col-xl-12'>
                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                      <span className='required'>
                                        <Translator name='RESIM' />
                                      </span>
                                    </label>
                                    <input
                                      type='file'
                                      className='form-control form-control-solid'
                                      name={`banners.${index}.File`}
                                      onChange={(event) => {
                                        defterdar_log(event.target.files)
                                        props.setFieldValue(
                                          `banners.${index}.File`,
                                          event.target.files?.[0]
                                        )
                                      }}
                                    />

                                    {isUrl(props.values.banners.at(index)?.Image ?? '') && (
                                      <div>
                                        <a href={props.values.banners.at(index)?.Image}></a>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='d-flex flex-column mb-7 col-xl-12'>
                                    {isUrl(props.values.banners.at(index)?.Image ?? '') && (
                                      <div>
                                        <a href={props.values.banners.at(index)?.Image} target='_'>
                                          Aktif Görsel
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='col-xl-5'>
                                <div className='d-flex flex-column mb-7 col-xl-12'>
                                  <FormInput
                                    fieldAttributes={{
                                      type: 'text',
                                      name: `banners.${index}.Description`,
                                      placeholder: 'ACIKLAMA',
                                      as: 'textarea',
                                      row: 5,
                                    }}
                                    istouched={true}
                                    errorMessage={undefined}
                                    placeHolderOnTop={true}
                                    checkValid={false}
                                  />
                                </div>
                              </div>
                              <div className='col-xl-1 d-flex align-items-end flex-column'>
                                <button
                                  type='button'
                                  className='btn btn-sm btn-icon btn-color-danger btn-active-light-danger'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/abstract/abs012.svg'
                                    className='svg-icon-muted svg-icon-2hx'
                                  />
                                </button>

                                {index > 0 && (
                                  <button
                                    type='button'
                                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary mt-10'
                                    onClick={() => arrayHelpers.move(index, index - 1)}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr003.svg'
                                      className='svg-icon-muted svg-icon-2hx'
                                    />
                                  </button>
                                )}

                                {index < props.values.banners.length - 1 && (
                                  <button
                                    type='button'
                                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary mt-10'
                                    onClick={() => arrayHelpers.move(index, index + 1)}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr004.svg'
                                      className='svg-icon-muted svg-icon-2hx'
                                    />
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    <div className='text-center'>
                      <button
                        type='button'
                        className='btn btn-sm btn-success me-3 mt-5'
                        onClick={() => arrayHelpers.push({})}
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr009.svg'
                          className='svg-icon-muted svg-icon-hx'
                        />
                        Banner Ekle
                      </button>
                    </div>
                  </>
                )}
              />
              <div className='row'>
                <div className='text-center'>
                  <button type='submit' className='btn btn-lg btn-primary me-3 mt-5'>
                    Kaydet
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      }}
    </FormikForm>
  )
}

export default HomePageBanners
