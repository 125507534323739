import {
  AdminPanelCompanyDTO,
  AssignedCompanyDTO,
  AssignedCompanyDTOApiData,
  GetAssignedCompanyRequest,
} from './../api/data-contracts'
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from './store'
import {Api_ServiceProvider_GetAssignedCompany} from '../apiClient/apiClient'
import {setIsLoading, postRequestAsync} from './commonSlice'

export interface AdvisorState {
  assignedCompanies: AdminPanelCompanyDTO[] | undefined | null
  assignedCompay?: AssignedCompanyDTO | undefined | null
}

const initialState: AdvisorState = {
  assignedCompanies: null,
  assignedCompay: undefined,
}

export const advisorSlice = createSlice({
  name: 'advisor',
  initialState: initialState,
  reducers: {
    setAssignedCompanies: (
      state,
      action: PayloadAction<AdminPanelCompanyDTO[] | undefined | null>
    ) => {
      state.assignedCompanies = action.payload
    },
    setAssignedCompay: (state, action: PayloadAction<AssignedCompanyDTO | undefined>) => {
      state.assignedCompay = action.payload
    },
  },
})

export const loadAssingedCompanyAsync = createAsyncThunk(
  'api/loadAssingedCompanyAsync',
  async (payload: {companyId: string | null}, thunkAPI) => {
    const advisorState = (thunkAPI.getState() as RootState).advisor

    thunkAPI.dispatch<any>(setAssignedCompay(undefined))

    const body: GetAssignedCompanyRequest = {
      companyId: payload.companyId ?? advisorState?.assignedCompay?.company?.guid ?? '',
    }

    await thunkAPI.dispatch(setIsLoading(true))
    thunkAPI.dispatch<any>(setAssignedCompay())
    await thunkAPI
      .dispatch<any>(
        postRequestAsync({
          postRequestFunction: Api_ServiceProvider_GetAssignedCompany,
          body: body,
        })
      )
      .unwrap()
      .then(async (response: AssignedCompanyDTOApiData) => {
        thunkAPI.dispatch<any>(setAssignedCompay(response.data))
        await thunkAPI.dispatch(setIsLoading(false))
      })
  }
)

export const reloadAssingedCompanyAsync = createAsyncThunk(
  'api/loadAssingedCompanyAsync',
  async (payload, thunkAPI) => {
    const advisorState = (thunkAPI.getState() as RootState).advisor

    await thunkAPI.dispatch<any>(
      loadAssingedCompanyAsync({
        companyId: advisorState?.assignedCompay?.company?.guid ?? '',
      })
    )
  }
)

export const {setAssignedCompanies, setAssignedCompay} = advisorSlice.actions
export default advisorSlice.reducer

export const getAdvisorState = (state: RootState) => state.advisor
